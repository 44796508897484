export const isObjectEmpty = (objectName) => {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
};

export const isPointsContainedFromHtmlElement = (points, bounds) => {
    return (
        points.y >= bounds.top &&
        points.y <= bounds.bottom &&
        points.x >= bounds.left  &&
        points.x <= bounds.right
    );
}

export const displayCoordsInBrowser = ({
    id = 'test1',
    top,
    left,
    bottom,
    right,
    width = 50,
    height = 50,
    color = 'red'
}) => {
    if (document.getElementById(id)) {
        const el = document.getElementById(id);
        el.remove();
    }

    const el = document.createElement('div');
    el.id = id;
    el.style.position = 'absolute';
    el.style.background = color;
    el.style.opacity = 0.3;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.top = `${top}px`;
    el.style.bottom = `${bottom}px`;
    el.style.left = `${left}px`;
    el.style.right = `${right}px`;
    // el.style.transform = `translate(-50%, -50%)`;
    // el.style.marginLeft = `-${width / 2}px`;
    // el.style.marginTop = `-${height / 2}px`;
    el.style.pointerEvents = 'none';

    document.body.appendChild(el);
}