import { createSlice } from '@reduxjs/toolkit';

const collaboratorsSlice = createSlice({
    name: 'collaborators',
    initialState: [],
    reducers: {
        initCollaborators: (state, action) => {
            return action.payload.collaborators;
        },
        removeCollaborator: (state, action) => {
            const collaboratorIdx = state.findIndex((collaborator) => {
                return collaborator.id === action.payload?.collaborator?.id;
            });

            if (collaboratorIdx > -1) {
                state.splice(collaboratorIdx, 1);
            }
        },
        addNewCollaborator: (state, action) => {
            state.unshift(action.payload.collaborator);
        },
        updateCollaborators: (state, action) => {
            if (!Array.isArray(action.payload?.collaborators) || !action.payload.signedUserId) {
                return state;
            }

            return action.payload.collaborators.filter((collaborator) => {
                return collaborator.userId !== action.payload.signedUserId;
            });
        },
        partialUpdateCollaborators: (state, action) => {
            return state.map((collaborator) => {
                return action.payload.userId === collaborator.userId ? {...collaborator, name: action.payload.name} : collaborator
            })  
        },
        clearCollaborators: () => {
            return [];
        },
    },
});


export default collaboratorsSlice.reducer;

