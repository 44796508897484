import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import './Tooltip.scss';
import clsx from 'clsx';

const Tooltip = ({
    text,
    position,
    usePortal,
    dimensions = {},
    className
}) => {

    if (usePortal) {
        return createPortal(
            <span
                className={clsx('customTooltip __hovered', { [className]: true })}
                data-position={position}
                style={{
                    left: dimensions?.left,
                    top: dimensions?.top,
                }}
            >
                {text}
            </span>,
            document.body
        );
    }

    return (
        <span
            className={clsx('customTooltip', { [className]: true })}
            data-position={position}
        >
            {text}
        </span>
    )
};

Tooltip.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    position: PropTypes.oneOf([
        'top',
        'bottom',
        'left',
        'right'
    ]),
    usePortal: PropTypes.bool,
    dimensions: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number
    }),
    className: PropTypes.string,
}

Tooltip.defaultProps = {
    position: 'bottom',
    usePortal: false
}

export default Tooltip;