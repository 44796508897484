import { HTML_EDITOR_ACTION_TYPES } from '../../helpers/Constant';

const HtmlEditorReducer = (state, action) => {
    console.log(`[${action.type}] is run`);

    switch (action.type) {
        case HTML_EDITOR_ACTION_TYPES.INIT_EDITOR: {
            const textboxObject = action.payload.target.getObjects().find((obj) => obj.type === 'textbox');
            const isTextOverflow = textboxObject?.isTextShortened === true;

            return {
                ...action.payload,
                textboxObject,
                isVisible: true,
                isTextOverflow: isTextOverflow,
                overflowStatus: {
                    canScrollDown: isTextOverflow,
                    canScrollUp: false
                }
            }
        }
        case HTML_EDITOR_ACTION_TYPES.EDITOR_CLOSED: {
            return {
                isVisible: false,
                target: null,
                textboxObject: null,
                isEdit: false,
                isTextOverflow: false,
                overflowStatus: {
                    canScrollDown: false,
                    canScrollUp: false
                }
            }
        }
        case HTML_EDITOR_ACTION_TYPES.EDITING_MODE_TURNED_ON: {
            return {
                ...state,
                isEdit: true,
                // isTextOverflow: false,
                overflowStatus: {
                    canScrollDown: false,
                    canScrollUp: false
                }
            }
        }
        case HTML_EDITOR_ACTION_TYPES.TEXT_OVERFLOW_STATUS_CHANGED: {
            const isTextOverflow = action.payload?.checkManual ?
                action.payload.isTextOverflow === true :
                !state.isEdit && state?.textboxObject?.isTextShortened === true;

            let updates = {}

            if (!isTextOverflow) {
                updates.overflowStatus = {
                    canScrollDown: false,
                    canScrollUp: false
                };
            } else if (state.overflowStatus.canScrollDown !== true) {
                updates.overflowStatus = {
                    canScrollDown: true
                }
            }

            return {
                ...state,
                ...updates,
                isTextOverflow
            }
        }
        case HTML_EDITOR_ACTION_TYPES.OVERFLOW_STATUSES_UPDATED: {
            return {
                ...state,
                overflowStatus: {
                    ...state.overflowStatus,
                    ...action.payload
                }
            }
        }
        default:
            return state;
    }
}

export default HtmlEditorReducer;