import { fabric } from 'fabric';
import { removeCanvasListener } from '../helpers/CommonFunctions';
import { changeCommentRefPositions } from './UseComment';
import { isPointerContainedWithObject, makeObjectUnselectable, setObjectMovement, getCanvasObjectsBbox } from '../helpers/FabricMethods';
import { isAbleToZoomOut } from '../helpers/ZoomHelper';
import {SHAPE_DEFAULTS} from '../helpers/Constant';
import { FitToScreenAssistance } from '../helpers/FitToScreenAssistance'
import {Viewport} from '../core/viewPort/Viewport';

const STATE_IDLE = 'idle',
    STATE_PANNING = 'panning';

export const onZoomIn = (canvas) => {
    if (canvas.getZoom() * 1.1 < 7) canvas.setZoom(canvas.getZoom() * 1.1);
    else canvas.setZoom(7);
    canvas.fire('board:zoom');
    canvas.renderAll();
    changeCommentRefPositions(canvas);
};

export const onZoomOut = (canvas) => {
    let newZoomValue = canvas.getZoom() / 1.1;
    const canZoomOut = isAbleToZoomOut(canvas, newZoomValue);
    if (canZoomOut === false) {
        if (canvas.getZoom() === 0.3) return
        newZoomValue = 0.3;
    }

    if (canvas.getZoom() / 1.1 > 0.01) canvas.setZoom(newZoomValue);
    else canvas.setZoom(0.01);

    canvas.renderAll();
    canvas.fire('board:zoom');
    changeCommentRefPositions(canvas);
};

export const pan = (canvas, dragMode) => {
    let lastClientX, // Remember the previous X and Y coordinates for delta calculations
        lastClientY,
        state = STATE_IDLE; // Keep track of the state

    // We're entering dragmode
    if (dragMode) {
        // Discard any active object
        canvas.isPanMode = true;
        canvas.discardActiveObject();

        // Set the cursor to 'move'
        canvas.defaultCursor = 'grab';

        // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
        canvas.forEachObject(function (object) {
            // disable selection for objects but comments
            if (object.type !== 'comment') {
                makeObjectUnselectable(object);
            } else {
                // if it is a comment, we lock the movement
                setObjectMovement(object, true);
            }
        });

        // Remove selection ability on the canvas
        canvas.selection = false;

        // When MouseUp fires, we set the state to idle
        canvas.on('mouse:up', function () {
            state = STATE_IDLE;
        });

        // When MouseDown fires, we set the state to panning
        canvas.on('mouse:down', (e) => {
            const target = e.target;
            // if the target is comment, we don't pan
            if (!target || target.type !== 'comment') {
                state = STATE_PANNING;
                lastClientX = e.e.clientX;
                lastClientY = e.e.clientY;
            }

            canvas.fire('board:pan-mousedown', e);
        });

        // When the mouse moves, and we're panning (mouse down), we continue
        canvas.on('mouse:move', (e) => {
            if (state === STATE_PANNING && e && e.e) {
                // let delta = new fabric.Point(e.e.movementX, e.e.movementY); // No Safari support for movementX and movementY
                // For cross-browser compatibility, I had to manually keep track of the delta

                // Calculate deltas
                let deltaX = 0;
                let deltaY = 0;
                if (lastClientX) {
                    deltaX = e.e.clientX - lastClientX;
                }
                if (lastClientY) {
                    deltaY = e.e.clientY - lastClientY;
                }
                // Update the last X and Y values
                lastClientX = e.e.clientX;
                lastClientY = e.e.clientY;

                let delta = new fabric.Point(deltaX, deltaY);
                canvas.relativePan(delta);

                canvas.fire('board:pan');
                changeCommentRefPositions(canvas);
            } else if (state === STATE_IDLE && e && e.e) {
                e.panMode = true;
                const objects = canvas.getObjects();
                const pointer = canvas.getPointer(e.e, false);
                const target = objects.find((o) => isPointerContainedWithObject(o, pointer));

                if (target) {
                    e.target = target;
                    canvas.fire('hyperlink:mousemove', e);
                } else {
                    canvas.fire('hyperlink:remove', e);
                }
            }
        });
    } else {
        canvas.isPanMode = false;
        // When we exit dragmode, we restore the previous values on all objects
        canvas.forEachObject(function (object) {
            object.evented = (object.prevEvented !== undefined) ? object.prevEvented : object.evented;
            object.selectable = (object.prevSelectable !== undefined) ? object.prevSelectable : object.selectable;
            if (object?.type === 'comment') {
                setObjectMovement(object, false);
            }
        });

        // Reset the cursor
        canvas.defaultCursor = 'default';

        // Remove the event listeners
        removeCanvasListener(canvas);

        // Restore selection ability on the canvas
        canvas.selection = true;
    }
};

export const onWheelZoomInOut = (opt, canvas, isLassoMode = false, isMouseDown = false) => {
    if(isLassoMode && isMouseDown) return;
    let delta = opt.e.deltaY,
        zoom = canvas.getZoom();
    const isPressingCtrl = opt.e.ctrlKey || opt.e.metaKey;

    // Using this flag to force the objects as using cache during rendering.
    canvas.cacheOperation = 'DISABLED';

    if (isPressingCtrl) {
        delta *= 7;
        zoom *= 0.999 ** delta;
        if (zoom > 7) zoom = 7;
        if (zoom < 0.01) zoom = 0.01;
      
        const canZoomOut = isAbleToZoomOut(canvas, zoom);
        const isZoomOut = zoom < canvas.getZoom();
        if (canZoomOut === false && isZoomOut) {
            if (canvas.getZoom() === 0.3) return
            zoom = 0.3;
        }

        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
        changeCommentRefPositions(canvas);
        canvas.fire('board:zoom');
    } else {
        const delta = new fabric.Point(-opt.e.deltaX, -opt.e.deltaY);
        canvas.relativePan(delta)
        changeCommentRefPositions(canvas);
        canvas.fire('board:pan');
    }

    canvas.fire('close-context-menu');

    opt.e.preventDefault();
    opt.e.stopPropagation();
};

/**
 * @param {fabric.Canvas} canvas 
 * @param {FitToScreenAssistance} fitToScreenAssistance A helper class for assisting user to remove far-placed objects 
 * that cause canvas to not fit to the screen.
 */
export const fitToScreen = (canvas, fitToScreenAssistance = null, options = {}) => {
    const bbBox = getCanvasObjectsBbox(canvas);
    if (!bbBox) {
        if (fitToScreenAssistance && fitToScreenAssistance.isNavigateStarted) {
            fitToScreenAssistance.handleSuccessfulFit()
        }
        return;
    }
    
    const {
        left, 
        top, 
        oLeft,
        oTop,
        width: objWidth, 
        height: objHeight ,
        shapesObjWidth,
        shapesObjHeight
    } = bbBox

    canvas.setViewportTransform([0, 0, 0, 0, 0, 0]);

    let zoom = Math.min(canvas.getHeight() / objHeight, canvas.getWidth() / objWidth);
    zoom = Math.max(Math.min(zoom, 7), 0.01);

    // Adding margin all corners of the canvas
    zoom *= 0.8;

    const panX = ((canvas.getWidth() / zoom / 2) - left - (objWidth / 2)) * zoom;
    const panY = ((canvas.getHeight() / zoom / 2) - top - (objHeight / 2)) * zoom;

    canvas.setViewportTransform([zoom, 0, 0, zoom, panX, panY]);
    canvas.renderAll();
    changeCommentRefPositions(canvas);
    if (fitToScreenAssistance) {
        if (!options.fromFitToScreenAssistance && fitToScreenAssistance.isNavigateStarted ) {
            fitToScreenAssistance.destroy(true)
        }
        const viewportOfObjects = new Viewport(oLeft, oTop, shapesObjWidth, shapesObjHeight)
        fitToScreenAssistance.check(canvas.getBoundingBox(), viewportOfObjects)
    }
};

export const getShapeStrokeWidthValueBaseOnZoomLevel = (canvas) => {
    return (
        (SHAPE_DEFAULTS.STROKE_WIDTH - 1 + canvas.getZoom()) / canvas.getZoom()
    );
};
export const changeTheBorderBaseOnZoomLevel = (canvas) => {
    canvas.getObjects().forEach((object) => {
        if (object.type === 'group' || object.type === 'frame') {
            const shape = Array.isArray(object._objects)
                ? object.getObjects()[0]
                : null;
            const newStrokeValue = getShapeStrokeWidthValueBaseOnZoomLevel(canvas);

            if (
                object.shapeType !== 'sticky' &&
          object.shapeType !== 'frame' &&
          shape
            ) {
                shape.set({
                    strokeWidth: newStrokeValue,
                    objectCaching: false,
                    width: object.width - newStrokeValue,
                    height: object.height - newStrokeValue,
                });
            } else if (object.shapeType === 'frame' && shape) {
                shape.set({ strokeWidth: newStrokeValue });
            }
        }
    });
    canvas.renderAll();
};

export const setZoomLevel = (zoomLevel, canvas, canvasRef) => {
    const newZoomLevel = zoomLevel / 100;
    const centerPoints = {
        x: canvasRef.current.offsetWidth / 2,
        y: canvasRef.current.offsetHeight / 2,
    };
    canvas.zoomToPoint(centerPoints, newZoomLevel);
    canvas.discardActiveObject().requestRenderAll();
    canvas.fire('board:zoom');
    changeTheBorderBaseOnZoomLevel(canvas);
};


