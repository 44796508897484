import { combineReducers } from '@reduxjs/toolkit';

import BoardReducer from './slices/board/BoardReducer';
import historyReducer from './slices/history/HistoryReducer';
import leftDrawerReducer from './slices/leftDrawer/LeftDrawerReducer';
import rightDrawerReducer from './slices/rightDrawer/RightDrawerReducer';
import ModalReducer from './slices/modal/ModalReducer';
import socketReducer from './slices/socket/SocketReducer';
import userReducer from './slices/user/UserReducer';
import activityReducer from './slices/activity/ActivityReducer';
import collaboratorsReducer from './slices/collaborators/CollaboratorsReducer';
import uiReducer from './slices/ui/UiReducer';


const rootReducer = combineReducers({
    board: BoardReducer,
    history: historyReducer,
    activityHistory: activityReducer,
    socket: socketReducer,
    leftDrawer: leftDrawerReducer,
    rightDrawer: rightDrawerReducer,
    modal: ModalReducer,
    user: userReducer,
    collaborators: collaboratorsReducer,
    ui: uiReducer
});

export default rootReducer;