import { fabric } from 'fabric';
import { SHAPE_DEFAULTS, TABLE_DEFAULTS } from '../Constant';
import { rotatePointBack } from '../lines/AttachedObjectTransformHandlers';
import { getShapeTextareaDimensions } from '../shapes/Common';
import HtmlEditorHelper from '../../helpers/HtmlEditorHelper';


/**
 * @private
 * @param {CanvasRenderingContext2D} ctx Context to render on
 */
export function _render(ctx) {
    let path = this.path;
    path && !path.isNotVisible() && path._render(ctx);

    // Remove overflowed texts.
    if (this.type === 'textbox' && this.group) {
        const { width, height } = HtmlEditorHelper.calculateHtmlEditorDimensions(this.group, this.group?.canvas, { isTextOverflow: false, overflowStatus: {}, getScaledDims: true });
        // create a clipping region for the shape so that the text doesn't overflow
        ctx.beginPath();
        ctx.rect(-width / 2, -height / 2, width, height);
        ctx.clip();
    }

    this._setTextStyles(ctx);
    this._renderTextLinesBackground(ctx);
    this._renderTextDecoration(ctx, 'underline');
    this._renderText(ctx);
    this._renderTextDecoration(ctx, 'overline');
    this._renderTextDecoration(ctx, 'linethrough');
}

/**
 * @override
 * @param {CanvasRenderingContext2D} ctx - Context to render on.
 * @private
 */
export function _renderText(ctx) {
    if (this.paintFirst === 'stroke') {
    // @override -> Don't render stroke at now. Because we don't provide stroke feature and no need to render texts again.
    // this._renderTextStroke(ctx);
        this._renderTextFill(ctx);
    }
    else {
        this._renderTextFill(ctx);
    // @override -> Don't render stroke at now. Because we don't provide stroke feature and no need to render texts again.
    // this._renderTextStroke(ctx);
    }
}

/**
 * @param {CanvasRenderingContext2D} ctx - Context to render on.
 * @param {string} method - Method name ("fillText" or "strokeText").
 * @private
 */
export const _renderTextCommon = function(ctx, method) {
    ctx.save();
    let lineHeights = 0, left = this._getLeftOffset(), top = this._getTopOffset();
    this.hyperLinkPositions = [];

    for (var i = 0, len = this._textLines.length; i < len; i++) {
        var heightOfLine = this.getHeightOfLine(i),
            maxHeight = heightOfLine / this.lineHeight,
            leftOffset = this._getLineLeftOffset(i);
        this._renderTextLine(
            method,
            ctx,
            this._textLines[i],
            left + leftOffset,
            top + lineHeights + maxHeight,
            i
        );
        lineHeights += heightOfLine;
    }
    ctx.restore();
}


/**
 * @param {string} method - FillText or strokeText.
 * @param {CanvasRenderingContext2D} ctx - Context to render on.
 * @param {Array} line - Content of the line, splitted in an array by grapheme.
 * @param {number} left
 * @param {number} top
 * @param {number} lineIndex
 * @private
 */

export const _renderChars = function() {
    let hyperLinkLeft = null;

    return function(method, ctx, line, left, top, lineIndex) {
    // set proper line offset
        let lineHeight = this.getHeightOfLine(lineIndex),
            isJustify = this.textAlign.indexOf('justify') !== -1,
            actualStyle,
            nextStyle,
            charsToRender = '',
            charBox,
            boxWidth = 0,
            timeToRender,
            hyperLinks = [],
            path = this.path,
            shortCut = !isJustify && this.charSpacing === 0 && this.isEmptyStyles(lineIndex) && !path,
            isLtr = this.direction === 'ltr', sign = this.direction === 'ltr' ? 1 : -1,
            hasHyperlink = !!(this.hasHyperlink || this.group?.hasHyperlink),
            isActiveSelection = (
                this.canvas?._activeObject &&
          this.canvas._activeObject.type === 'activeSelection' &&
          this.canvas._activeObject.shapeType !== 'loadingMockImagesGroup' &&
          this.canvas._activeObject.getObjects().some((o) => {
              return o.uuid === this.uuid || o.uuid === this.group?.uuid;
          })
            ),
            isLinkedToGroup = isActiveSelection ? this.group?.type === 'group' : !!(this.group),
            group = this.group,
            isTableTextbox = (this.type === 'cellText') || (this.type === 'textbox' && this.isTableTextbox),
            selectionStart = 0,
            drawingLeft, currentDirection = ctx.canvas.getAttribute('dir');
        ctx.save();
        if (currentDirection !== this.direction) {
            ctx.canvas.setAttribute('dir', isLtr ? 'ltr' : 'rtl');
            ctx.direction = isLtr ? 'ltr' : 'rtl';
            ctx.textAlign = isLtr ? 'left' : 'right';
        }

        top -= lineHeight * this._fontSizeFraction / this.lineHeight;

        if (shortCut) {
            // render all the line in one pass without checking
            // drawingLeft = isLtr ? left : left - this.getLineWidth(lineIndex);
            this._renderChar(method, ctx, lineIndex, 0, line.join(''), left, top, lineHeight);
            ctx.restore();
            return;
        }

        for (let i = 0, len = line.length - 1; i <= len; i++) {
            timeToRender = i === len || this.charSpacing || path;
            charsToRender += line[i];
            charBox = this.__charBounds[lineIndex][i];
            if (boxWidth === 0) {
                left += sign * (charBox.kernedWidth - charBox.width);
                boxWidth += charBox.width;

                // [OUR IMPROVEMENT]: If the character has hyperlink and if first matched then store the left position of the character.
                // We are storing this position to understand left position of hyperlink.
                if (hasHyperlink && this._getStyleDeclaration(lineIndex, i)?.isHyperlink && hyperLinkLeft === null) {
                    hyperLinkLeft = charBox.left;
                    selectionStart = i;

                    if (isTableTextbox) {
                        hyperLinkLeft += TABLE_DEFAULTS.cellTextPadding
                    }
                }
            }
            else {
                boxWidth += charBox.kernedWidth;
            }

            if (isJustify && !timeToRender) {
                if (this._reSpaceAndTab.test(line[i])) {
                    timeToRender = true;
                }
            }

            if (!timeToRender) {
                // if we have charSpacing, we render char by char
                actualStyle = actualStyle || this.getCompleteStyleDeclaration(lineIndex, i);
                nextStyle = this.getCompleteStyleDeclaration(lineIndex, i + 1);
                timeToRender = fabric.util.hasStyleChanged(actualStyle, nextStyle, false);
            }
            if (timeToRender) {
                // [OUR IMPROVEMENT]: If rendered object has hyperlink value, we are storing the all corners' positions.
                if (
                    hasHyperlink &&
          this._getLineStyle(lineIndex) &&
          this._getStyleDeclaration(lineIndex, i)?.isHyperlink === true
                ) {
                    // !IMPORTANT NOTE
                    // If the object is linked to a group, sometimes during the rendering process, the textbox won't be rendered even though group is rendered.
                    // So in this case, we are using group data to find and calculate hyperlink position instead of textbox object.

                    // Getting the scale of the group or textbox
                    const groupObjects = isLinkedToGroup ? group.getObjects() : [];
                    let scaleX = isLinkedToGroup ? group.scaleX : this.scaleX;
                    let scaleY = isLinkedToGroup ? group.scaleY : this.scaleY;

                    if (this.type === 'cellText') {
                        scaleX = this.tableScaleX;
                        scaleY = this.tableScaleY;
                    }

                    // After addWithUpdate method run, although scale value is equal to 1 for group, we can get the actual scale value from the first object of group.
                    if (isLinkedToGroup) {
                        const rect = groupObjects[1];
                        if (scaleY === 1 && rect?.scaleY !== 1) {
                            scaleY = rect.scaleY;
                        }

                        if (scaleX === 1 && rect?.scaleX !== 1) {
                            scaleX = rect.scaleX;
                        }
                    }

                    // Getting the left and top informations of the group or textbox.
                    // Note: If the object is group, then the originX and originY properties will be "center"
                    // and left, top properties are storing the center coordinates of the group. Keep in mind that.
                    let objLeftPos = 0;
                    let objTopPos = 0;

                    if (isLinkedToGroup) {
                        objLeftPos = isActiveSelection ? group.left + group.group.left + (group.group.width / 2) : group.left;
                        objTopPos = isActiveSelection ? (group.top - (this.height * scaleY / 2)) + group.group.top + (group.group.height / 2) : group.top - (this.height * scaleY / 2);
                    } else if (isTableTextbox) {
                        let cellTop = this.cellCoords.tl.y + TABLE_DEFAULTS.cellTextPadding;
                        let cellLeft = this.cellCoords.tl.x;

                        objLeftPos = isActiveSelection ? cellLeft + this.group.left + (this.group.width / 2) : cellLeft;
                        objTopPos = isActiveSelection ? cellTop + this.group.top + (this.group.height / 2) : cellTop;
                    } else {
                        objLeftPos = isActiveSelection ? this.left + this.group.left + (this.group.width / 2) : this.left;
                        objTopPos = isActiveSelection ? this.top + this.group.top + (this.group.height / 2) : this.top;
                    }

                    const width = boxWidth * scaleX; 

                    // Calculate left position for groups
                    if (isLinkedToGroup) {
                        const lineWidth = this.__lineWidths[lineIndex];
                        const centerPoints = group.getCenterPoint();
                        const rectangleLeft = groupObjects[0].getPointByOrigin('left', 'top').x;
                        const textboxLeft = groupObjects[1].getPointByOrigin('left', 'top').x;

                        // Some margins are manually added to the group. So we need to extract or add these margins in below code section.
                        const textMarginX = Math.abs(rectangleLeft - textboxLeft) * scaleX;
                        const textMarginY = group.shapeType === 'triangle' ? groupObjects[1]?.top : getShapeTextareaDimensions(group)?.top;

                        if (this.textAlign === 'left') {
                            let leftPoints = group.getPointByOrigin('left', 'top');

                            if (isActiveSelection) {
                                // 1. get the matrix for the object.
                                const matrix = group.calcTransformMatrix();
                                // 2. choose the point you want, fro example top, left.
                                const point = { x: -group.width/2, y: group.height/2 };
                                // 3. transform the point
                                const pointOnCanvas = fabric.util.transformPoint(point, matrix)

                                leftPoints = pointOnCanvas;

                                centerPoints.x += group.group.getCenterPoint().x;
                                centerPoints.y += group.group.getCenterPoint().y;
                            }

                            // If rotation exists, rotate it to back.
                            objLeftPos = rotatePointBack(
                                leftPoints.x,
                                leftPoints.y,
                                centerPoints.x,
                                centerPoints.y,
                                group.angle
                            ).x;
                            
                            objLeftPos += textMarginX;
                        } else if (this.textAlign === 'center') {
                            objLeftPos -= (lineWidth / 2) * scaleX;
                        } else if (this.textAlign === 'right') {
                            let rightPoints = group.getPointByOrigin('right', 'top');

                            if (isActiveSelection) {
                                // 1. get the matrix for the object.
                                const  matrix = group.calcTransformMatrix();
                                // 2. choose the point you want, fro example top, left.
                                const point = { x: group.width/2, y: group.height/2 };
                                // 3. transform the point
                                const pointOnCanvas = fabric.util.transformPoint(point, matrix)

                                rightPoints = pointOnCanvas;

                                centerPoints.x += group.group.getCenterPoint().x;
                                centerPoints.y += group.group.getCenterPoint().y;
                            }

                            objLeftPos = rotatePointBack(
                                rightPoints.x,
                                rightPoints.y,
                                centerPoints.x,
                                centerPoints.y,
                                group.angle
                            ).x;

                            objLeftPos += (-(lineWidth * scaleX) - textMarginX);
                        }

                        objLeftPos += hyperLinkLeft * scaleX;
            
                        // For triangles, there is additional padding to top.
                        if (group.shapeType === 'triangle') {
                            objTopPos += (textMarginY * scaleY);
                        }
                    } else {
                        let leftPos = hyperLinkLeft * scaleX;

                        // If the object aligned to center or right, then we need to get left offset.
                        if (this.textAlign !== 'left') {
                            leftPos += (this._getLineLeftOffset(lineIndex) * scaleX);
                        }

                        objLeftPos += leftPos;
                    }

                    // Calculating top position. We are calculating all lines one by one till currenct line.
                    let topPos = Array.from(Array(Math.max(lineIndex, 0))).reduce((acc, curr, idx) => {
                        return acc + (this.getHeightOfLine(idx) * scaleY)
                    }, objTopPos);

                    const bottomPos = topPos + (this.getHeightOfLine(lineIndex) * scaleY);

                    let hoveredObject = this;
                    if (isLinkedToGroup) {
                        hoveredObject = group;
                    }

                    hyperLinks.push({
                        br: new fabric.Point(objLeftPos + width, bottomPos),
                        bl: new fabric.Point(objLeftPos, bottomPos),
                        tl: new fabric.Point(objLeftPos, topPos),
                        tr: new fabric.Point(objLeftPos + width, topPos),
                        url: this._getStyleDeclaration(lineIndex, i)?.url,
                        lineHeight: (lineHeight * this._fontSizeFraction / this.lineHeight),
                        lineIndex,
                        selectionStart,
                        selectionEnd: i,
                        hoveredObject
                    });
  
                    hyperLinkLeft = null;
                }

                if (path) {
                    ctx.save();
                    ctx.translate(charBox.renderLeft, charBox.renderTop);
                    ctx.rotate(charBox.angle);
                    this._renderChar(method, ctx, lineIndex, i, charsToRender, -boxWidth / 2, 0, lineHeight);
                    ctx.restore();
                }
                else {
                    drawingLeft = left;
                    this._renderChar(method, ctx, lineIndex, i, charsToRender, drawingLeft, top, lineHeight);
                }
                charsToRender = '';
                actualStyle = nextStyle;
                left += sign * boxWidth;
                boxWidth = 0;
            }
        }

        // [OUR IMPROVEMENT]: Assigned the all hyperlink corner positions to the object. Therefore the positions can be checked in mouse:move event.
        if (hyperLinks.length > 0) {
            this.hyperLinkPositions = [...this.hyperLinkPositions, ...hyperLinks];
        }

        ctx.restore();
    }
}

export const _renderChar = function(method, ctx, lineIndex, charIndex, _char, left, top) {
    let width = 0,
        index;
    if (this.restrictCharWidth) {
        for (index = 0; index < _char.length; index++) {
            const bbox = this.__charBounds[lineIndex][charIndex + index];
            width += bbox.width;
            if (width > this.width) {
                _char = _char.slice(charIndex, charIndex + index - 3) + '...';
                break;
            }
        }
    }

    let decl = this._getStyleDeclaration(lineIndex, charIndex),
        fullDecl = this.getCompleteStyleDeclaration(lineIndex, charIndex),
        shouldFill = method === 'fillText' && fullDecl.fill,
        shouldStroke = method === 'strokeText' && fullDecl.stroke && fullDecl.strokeWidth,
        fillOffsets, strokeOffsets;

    if (!shouldStroke && !shouldFill) {
        return;
    }
    ctx.save();

    shouldFill && (fillOffsets = this._setFillStyles(ctx, fullDecl));
    shouldStroke && (strokeOffsets = this._setStrokeStyles(ctx, fullDecl));

    ctx.font = this._getFontDeclaration(fullDecl);


    if (decl && decl.textBackgroundColor) {
        this._removeShadow(ctx);
    }
    if (decl && decl.deltaY) {
        top += decl.deltaY;
    }
    shouldFill && ctx.fillText(_char, left - fillOffsets.offsetX, top - fillOffsets.offsetY);
    shouldStroke && ctx.strokeText(_char, left - strokeOffsets.offsetX, top - strokeOffsets.offsetY);
    ctx.restore();
}

/**
 * Its  calculates the renderable lines of text / textbox.
 * @returns {{ renderableLinesCount: number, renderableLinesHeight: number, isAllLinesRenderable: boolean }}
 */
export function getCountOfRenderableLines() {
    let renderableLinesCount = this._textLines.length;
    let renderableLinesHeight = this.height;

    // If a shape's textbox...
    if (this.group) {
        let shapeHeight = this.group.height;

        if (this.group.shapeType === 'sticky') {
            shapeHeight -= SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT;
        }

        renderableLinesCount = 0;
        let currentHeight = 0;

        // Calculating the maximum lines which we can render
        for (let i = 0; i < this._textLines.length; i++) {
            const heightOfLine = this.getHeightOfLine(i);

            if (Math.ceil(currentHeight + heightOfLine) >= shapeHeight) {
                break;
            }

            renderableLinesCount += 1;
            currentHeight += heightOfLine;
        }

        renderableLinesHeight = currentHeight;
    }

    return {
        renderableLinesCount,
        renderableLinesHeight,
        isAllLinesRenderable: renderableLinesCount === this._textLines.length
    }
}