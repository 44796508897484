import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    isHtmlEditorVisible: false,
    isSubtoolbarVisible: false
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleHtmlEditorVisibility: (state, action) => {
            state.isHtmlEditorVisible = action.payload;
        },
        toggleSubtoolbarVisibility: (state, action) => {
            state.isSubtoolbarVisible = action.payload;
        }
    },
});

export default uiSlice.reducer;

