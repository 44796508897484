
export function setSelectionStyles(styles, startIndex, endIndex) {
    let cb = null;

    if (this.isTextShortened) {
        cb = this.extendShortenedText();
    }

    if (typeof startIndex === 'undefined') {
        startIndex = this.selectionStart || 0;
    }
    if (typeof endIndex === 'undefined') {
        endIndex = this.selectionEnd || startIndex;
    }
    for (var i = startIndex; i < endIndex; i++) {
        this._extendStyles(i, styles);
    }

    if (typeof cb === 'function') {
        cb();
    }
    /* not included in _extendStyles to avoid clearing cache more than once */
    this._forceClearCache = true;
    return this;
}