import PropTypes from 'prop-types';


export const ArrowDownCircle = ({
    width = 12,
    height = 12
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={6} cy={6} r={6} fill="white" />
            <path
                d="M8.28333 5L6.09963 7.04148C6.07101 7.06828 6.03217 7.08333 5.99166 7.08333C5.95116 7.08333 5.91232 7.06828 5.8837 7.04148L3.7 5"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

ArrowDownCircle.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}