import { useCallback, useEffect, useLayoutEffect, useRef, useState, memo, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.core.css';
import './HtmlEditor.scss';
import eventEmitter from '../../helpers/EventEmitter';
import { EMITTER_TYPES, HTML_EDITOR_ACTION_TYPES, SHAPE_DEFAULTS, TEXT_SIZE_OPTIONS, TEXTBOX_LINE_HEIGHT } from '../../helpers/Constant';
import DeltaHelper from '../../helpers/DeltaHelper';
import HtmlEditorHelper from '../../helpers/HtmlEditorHelper';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowUpCircle } from '../svgIcons/ArrowUpCircle';
import { ArrowDownCircle } from '../svgIcons/ArrowDownCircle';
import { getOverlappedObjects } from '../../helpers/StackOrder';
import { openShapeEditor } from '../../helpers/CommonFunctions';
import { getTextColorBaseOnShape } from '../../helpers/ColorHelper';
import { throttle } from '../../helpers/OptimizationUtils';
import htmlEditorReducer from './HtmlEditorReducer';
import { getBrowserName } from '../../helpers/BrowserInfo';

const HtmlEditor = ({
    canvas,
    formats,
    modules
}) => {
    const [state, dispatch] = useReducer(htmlEditorReducer, {
        target: null,
        isVisible: false,
        isEdit: false,
        isTextOverflow: false,
        overflowStatus: {
            canScrollDown: false,
            canScrollUp: false
        }
    });
    const reduxDispatch = useDispatch();
    const userId = useSelector((state) => state.user?.id);
    const editorRef = useRef(null);
    const targetRef = useRef(null);
    const canvasRef = useRef(null);
    const htmlEditorWrapperRef = useRef(null);
    const initialValueRef = useRef(null);
    const isEditorFocusedRef = useRef(false);
    const overflowStatusRef = useRef({});
    const callbackFuncRef = useRef(null);
    const continuesScrollRef = useRef(null);
    const lastSelection = useRef({ index: 0, length: 0 });
    const dimensionsRef = useRef({
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        transform: ''
    });
    const editorStylesRef = useRef({});
    const upArrowButtonRef = useRef({})
    const downArrowButtonRef = useRef({})
    const scrollTopPos = useRef(0);
    const [value, setValue] = useState('');

    targetRef.current = state.target;
    canvasRef.current = canvas;
    overflowStatusRef.current = state.overflowStatus;

    const initQuill = useCallback(() => {
        // Register editor font sizes
        const Size = Quill.import('attributors/style/size');
        Size.whitelist = modules.toolbar.size;
        Quill.register(Size, true);

        // Font Registration
        const Font = Quill.import('formats/font');
        Font.whitelist = modules.toolbar.font;
        Quill.register(Font, true);

        Quill.debug(false);
    }, []);

    const getDeltaValueOfTextbox = useCallback((shape, textboxObject) => {
        const { text, styles } = textboxObject;
        const deltaHelper = new DeltaHelper({});
        return deltaHelper.convertTextToDelta(shape, textboxObject, text, styles);
    }, []);

    const _handleScroll = useCallback((editor, textboxObject) => {
        scrollTopPos.current = editor.scrollingContainer.scrollTop;

        const threshold = 1;
        const canScrollUp = scrollTopPos.current > 0;
        const canScrollDown = editor.scrollingContainer.scrollHeight - (scrollTopPos.current + editor.scrollingContainer.clientHeight) > threshold;

        if (!state.isEdit && textboxObject?.isTextShortened && (
            (canScrollUp !== overflowStatusRef.current.canScrollUp) ||
            (canScrollDown !== overflowStatusRef.current.canScrollDown)
        )) {
            dispatch({
                type: HTML_EDITOR_ACTION_TYPES.OVERFLOW_STATUSES_UPDATED,
                payload: {
                    canScrollUp,
                    canScrollDown
                }
            });
        }
    }, []);

    const handleScroll = useCallback(throttle(_handleScroll, 100), [_handleScroll]);

    const handleEditorScroll = useCallback((e) => {
        if (!state.isVisible) return;
        if (!targetRef.current) return;

        const isZooming = e.type === 'wheel' && (e.ctrlKey || e.metaKey);
        if (isZooming) return;

        // Prevent the horizontal scroll
        // For firefox, scrolling is working a bit different.
        const isFirefox = getBrowserName() === 'Firefox';
        if (e.type === 'wheel' && (!isFirefox && e.deltaX !== 0) || (isFirefox && e.deltaX !== 0 && Math.abs(e.deltaX) > Math.abs(e.deltaY ?? 0))) {
            const coords = targetRef.current._getCoords(false, true);

            // If mouse coordinates is not inside of the shape, don't preventDefault. Otherwise, browser wheel handler is not worked.
            if (
                e.clientX <= coords.tr.x &&
                e.clientX >= coords.tl.x &&
                e.clientY <= coords.bl.y &&
                e.clientY >= coords.tl.y
            ) {
                e.preventDefault();
            }
            return false;
        }

        const textboxObject = targetRef.current.getObjects().find((obj) => obj.type === 'textbox');
        if (!textboxObject) return;

        const editor = editorRef.current.getEditor();
        if (editor.scrollingContainer.scrollTop === scrollTopPos.current) return;

        handleScroll(editor, textboxObject);
    }, [state.isVisible, state.isEdit, state.overflowStatus.canScrollDown, state.overflowStatus.canScrollUp, handleScroll]);

    const handleHorizontalScroll = useCallback((e) => {
        if (!state.isVisible || !state.isEdit) return;

        const isFirefox = getBrowserName() === 'Firefox';
        if (e.type === 'wheel' && (!isFirefox && e.deltaX !== 0) || (isFirefox && e.deltaX !== 0 && Math.abs(e.deltaX) > Math.abs(e.deltaY ?? 0))) {
            const coords = targetRef.current._getCoords(false, true);

            // If mouse coordinates is not inside of the shape, don't preventDefault. Otherwise, browser wheel handler is not worked.
            if (
                e.clientX <= coords.tr.x &&
                e.clientX >= coords.tl.x &&
                e.clientY <= coords.bl.y &&
                e.clientY >= coords.tl.y
            ) {
                e.preventDefault();
            }

            return false;
        }
    }, [state.isVisible, state.isEdit]);

    const handleEditorStyles = useCallback(() => {
        if (!state.isVisible) return;
        const target = targetRef.current;
        if (!target) return;

        const { scaleX } = target;
        const [, textboxObj] = target.getObjects();
        let { fontSize } = textboxObj;

        if (fontSize < 10) {
            fontSize = SHAPE_DEFAULTS.FONT_SIZE;
        }

        editorStylesRef.current = {
            fontSize: `${fontSize * scaleX}px`,
            lineHeight: TEXTBOX_LINE_HEIGHT,
            textAlign: textboxObj.textAlign
        };

        if (!target.isTextColorApplied) {
            editorStylesRef.current.color = getTextColorBaseOnShape(target);
        }

        if (!editorRef.current) return;
        const editor = editorRef.current.getEditor();
        if (!editor?.root) return;

        Object.entries(editorStylesRef.current).forEach(([key, value]) => {
            editor.root.style[key] = value;
        });

        if (textboxObj.isTextShortened !== state.isTextOverflow) {
            dispatch({ type: HTML_EDITOR_ACTION_TYPES.TEXT_OVERFLOW_STATUS_CHANGED });
        }
    }, [state.isVisible, state.isTextOverflow]);

    const sendUpdates = useCallback((content, textboxData = {}, options = {}) => {
        if (!state.isVisible || !state.target) return;

        let textboxObject = null;
        if (state.target?.type === 'group') {
            textboxObject = targetRef.current.getObjects()[1];
        }

        const deltaHelper = new DeltaHelper({});
        const result = deltaHelper.convertDeltaToFabric(content, { textboxObject });

        // Handle stick note and shapes as well
        if (state.target?.type === 'group') {
            const isHyperlinkExist = content.ops.some((item) => !!item?.attributes?.link);

            if (state.target.get('hasHyperlink') !== isHyperlinkExist) {
                state.target.hasHyperlink = isHyperlinkExist;
            }

            textboxObject.set({ ...textboxData, visible: !options.force, text: result.text, styles: result.styles });

            if (options?.force === true || deltaHelper.compareDelta(content, initialValueRef.current).areEqual !== true) {
                try {
                    state.target.modifiedBy = userId;
                    state.target.canvas.collaborationManager.completeContinuousEditing(state.target.editingProcessId);
                    state.target.canvas.fire('modified-with-event', {target: state.target});
                } catch (err) {
                    console.error('error while sending data: ', err)
                }
            }

            if (options?.force !== true) {
                state.target.onShapeChanged();
                state.target?.canvas?.renderAll();
            }
        }
    }, [state.isEdit, state.isVisible, userId]);

    const sendUpdatesManually = useCallback(() => {
        if (!state.isVisible || !state.target) return;
        const editor = editorRef.current.getEditor();
        const content = editor.getContents();
        sendUpdates(content, {}, { force: true });
        initialValueRef.current = content;
    }, [state.isEdit, state.isVisible, userId]);

    const updateEditorPosition = useCallback(() => {
        if (!state.isVisible) return;
        const target = targetRef.current;
        if (!target) { return; }

        const dimensions = HtmlEditorHelper.calculateHtmlEditorDimensions(
            target,
            canvasRef.current,
            {
                isTextOverflow: state.isEdit ? false : state.isTextOverflow,
                overflowStatus: state.overflowStatus
            });

        const wrapper = htmlEditorWrapperRef.current;
        if (!wrapper) return;

        const dims = {};
        Object.entries(dimensions).forEach(([key, value]) => {
            if (!['zoom', 'transform'].includes(key)) {
                value = `${value}px`;
            }

            dims[key] = value;
            wrapper.style[key] = value;
        });

        dimensionsRef.current = dims;
    }, [state.isVisible, state.isEdit, state.isTextOverflow, state.overflowStatus.canScrollDown, state.overflowStatus.canScrollUp]);

    const handleDimensionsUpdatedFlow = useCallback(() => {
        updateEditorPosition();
        updateArrowSizes();

        if (targetRef.current?.type === 'group') {
            const textboxObject = targetRef.current.getObjects().find((obj) => obj.type === 'textbox');

            if (textboxObject && textboxObject?.isTextShortened !== state.isTextOverflow) {
                dispatch({ type: HTML_EDITOR_ACTION_TYPES.TEXT_OVERFLOW_STATUS_CHANGED });
            }

            if (textboxObject?.isTextShortened) {
                const editor = editorRef.current.getEditor();

                if (
                    (scrollTopPos.current > 0 !== state.overflowStatus.canScrollUp) ||
                    (scrollTopPos.current + editor?.root?.clientHeight < editor?.root?.scrollHeight !== state.overflowStatus.canScrollDown)
                ) {
                    dispatch({
                        type: HTML_EDITOR_ACTION_TYPES.OVERFLOW_STATUSES_UPDATED,
                        payload: {
                            canScrollUp: scrollTopPos.current > 0,
                            canScrollDown: scrollTopPos.current + editor?.root?.clientHeight < editor?.root?.scrollHeight
                        }
                    });
                }
            }
        }
    }, [updateEditorPosition, state.isTextOverflow, state.overflowStatus.canScrollDown, state.overflowStatus.canScrollUp]);

    const updateArrowSizes = useCallback(() => {
        if (!state.isVisible || !state.isTextOverflow) return;

        const arrowWidth = 24;
        const zoom = canvasRef.current ? canvasRef.current.getZoom() : 1;
        let arrowSize = zoom > 1 ?
            arrowWidth :
            arrowWidth / zoom;

        const { width, height, scaleX, scaleY } = targetRef.current;

        let arrowScaling = 1;
        let editorWidth = width * scaleX;
        let editorHeight = height * scaleY;

        const els = [
            upArrowButtonRef.current,
            downArrowButtonRef.current,
        ];

        if (zoom >= 1) {
            const aSize = arrowWidth;

            const size = Math.min(editorWidth, editorHeight);
            const ratio = size / aSize || 0.1;

            if (ratio > 4 && zoom > 2) {
                arrowScaling = 0.5;
            } else if (ratio <= 1) {
                arrowScaling = 0.1;
            } else if (ratio <= 2) {
                arrowScaling = 0.3;
            } else if (ratio <= 3) {
                arrowScaling = 0.4;
            } else if (ratio <= 4) {
                arrowScaling = 0.5;
            }
        }

        els.forEach((el) => {
            el.style.width = `${Math.round(arrowSize)}px`;
            el.style.height = `${Math.round(arrowSize)}px`;
            el.style.transform = `translateX(-50%) scale(${arrowScaling}, ${arrowScaling})`;
        });
    }, [state.isVisible, state.isTextOverflow]);

    const handleScrollPositionOfTextarea = useCallback((newScrollTop) => {
        if (!state.isVisible) return;

        const editor = editorRef.current.getEditor();
        const newValue = newScrollTop ?? editor.scrollingContainer.scrollTop ;
        scrollTopPos.current = newValue;
        editor.scrollingContainer.scrollTop = newValue
    }, [state.isVisible]);

    const resetScrollPositionOfTextarea = useCallback((dontUpdateEditor = false) => {
        if (editorRef.current && dontUpdateEditor !== true) {
            const editor = editorRef.current.getEditor();
            editor.scrollingContainer.scrollTop = 0;
        }

        scrollTopPos.current = 0;
    }, []);

    const onZoomChanged = useCallback(() => {
        if (state.isVisible) {
            const scalingMode = HtmlEditorHelper.getScalingMode(canvasRef.current);
            const editor = editorRef.current.getEditor();
            const scrollPercentage = scrollTopPos.current / editor.root.scrollHeight;

            // Handle Arrow Sizes
            updateArrowSizes();
            handleEditorStyles();
            updateEditorPosition();

            // In zooming mode, scrollHeight and scrollTop is changed due to zoom css property. That's why we need to update it again.
            if (scalingMode === 'zoom') {
                const newScrollTop = Math.round(scrollPercentage * editor.root.scrollHeight);
                handleScrollPositionOfTextarea(newScrollTop);
            }
        }
    }, [canvas, state.isVisible, handleEditorStyles, updateEditorPosition, updateArrowSizes, handleScrollPositionOfTextarea]);

    const onSelectionUpdated = useCallback((selection, triggerer) => {
        if (selection !== null && triggerer !== 'silent') { // Condition added because if user lose the selection; this function also triggered by react-quill.
            eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_SELECTION_UPDATED, { target: targetRef.current });
        }

        setTimeout(() => {
            lastSelection.current = selection;
        }, 0);
    }, []);

    const onEditorChanged = useCallback((opt, t, q, actions) => {
        if (!state.isEdit) {
            if (initialValueRef.current) {
                const deltaHelper = new DeltaHelper();
                const newContent = actions.getContents();

                // !NOTE: Becareful on this condition. There might be render loop issue if its removed or updated.
                if (deltaHelper.compareDelta(initialValueRef.current, newContent).areEqual !== true) {
                    setValue(newContent)
                    initialValueRef.current = newContent;
                }
            }

            return;
        }
        
        const content = actions.getContents();
        if (content) {
            let textboxObject
            if (targetRef.current?.type === 'group') {
                textboxObject = targetRef.current.getObjects()[1];
            }

            const deltaHelper = new DeltaHelper({});
            const result = deltaHelper.convertDeltaToFabric(content, { textboxObject });
            textboxObject.set({ text: result.text, styles: result.styles });

            canvasRef.current.collaborationManager.updateContinuousEditing(
                targetRef.current.editingProcessId
            ) 
        }

        setValue(content);
    }, [state.isEdit]);

    const openTextEditor = useCallback(({
        target,
        isEdit = true,
        callback
    }) => {
        if (target.type === 'group') {
            const textboxObject = target.getObjects()[1];
            const delta = getDeltaValueOfTextbox(target, textboxObject);

            setValue(delta);
        }

        target.set({ isHtmlEditingMode: true, htmlMode: isEdit ? 'edit' : 'read' });

        if (isEdit !== true) {
            resetScrollPositionOfTextarea();
        }

        dispatch({
            type: HTML_EDITOR_ACTION_TYPES.INIT_EDITOR,
            payload: { target, isEdit }
        });

        reduxDispatch({
            type: 'ui/toggleHtmlEditorVisibility',
            payload: true
        });

        // Set subtoolbar again after entering editing mode.
        eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);

        callbackFuncRef.current = callback;
    }, [updateEditorPosition, getDeltaValueOfTextbox, handleEditorStyles, resetScrollPositionOfTextarea]);

    const closeTextEditor = useCallback((isUpdated = true) => {
        if (state.target?.htmlTextEditingAborted) {
            state.target.htmlTextEditingAborted = false;
            return;
        }
        if (state.isVisible !== true || !targetRef.current) return;

        let textboxObject = null;
        if (state.target?.type === 'group') {
            textboxObject = state.target.getObjects()[1];
        }

        // No need to update textarea dims from now on. I don't remove the flow currently. We may need it in future.
        // const textareaDims = getShapeTextareaDimensions(state.target);
        const textboxData = {}
        if (state.target?.shapeType === 'sticky' && textboxObject.top !== -SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT / 4) {
            textboxData.top = -SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT / 4;
        }

        if (isUpdated) {
            const content = editorRef.current.editor.getContents();
            sendUpdates(content, textboxData);
        } else if (textboxObject) {
            textboxObject.set({ ...textboxData, visible: true });
        }

        isEditorFocusedRef.current = false;
        callbackFuncRef.current = null;
        lastSelection.current = { index: 0, length: 0 }
        resetScrollPositionOfTextarea();

        HtmlEditorHelper.stopEditingForLockManager(targetRef.current, canvasRef.current);

        state.target.set({ isHtmlEditingMode: false, htmlMode: null });
        dispatch({ type: HTML_EDITOR_ACTION_TYPES.EDITOR_CLOSED });

        reduxDispatch({
            type: 'ui/toggleHtmlEditorVisibility',
            payload: false
        });
    }, [state.isVisible, sendUpdates, resetScrollPositionOfTextarea]);
    
    const abortTextEditing = useCallback(() => {
        const target = targetRef.current;
        if (!target) {
            return
        }
        
        let textboxObject = null;
        if (target.type === 'group') {
            textboxObject = target.getObjects()[1];
        }
        target.onShapeChanged()
        textboxObject.set({ visible: true });
        isEditorFocusedRef.current = false;
        callbackFuncRef.current = null;
        resetScrollPositionOfTextarea();

        target.set({ isHtmlEditingMode: false, htmlMode: null });
        dispatch({ type: HTML_EDITOR_ACTION_TYPES.EDITOR_CLOSED });

        dispatch({
            type: 'ui/toggleHtmlEditorVisibility',
            payload: false
        }); 
    }, [dispatch, resetScrollPositionOfTextarea])

    const handleOverlapping = useCallback((event) => {
        const allowedActions = ['drag', 'rotate', 'scale', 'skew', 'z-index-update', 'moved_by_key'];
        if (!allowedActions.includes(event?.action)) { return; }

        const activeObject = canvasRef.current.getActiveObject();
        if (activeObject?.type === 'group') {
            const overlappedObjects = getOverlappedObjects(activeObject, canvasRef.current, { dontConsiderConnectors: true });
            if (overlappedObjects.length === 0 && !state.isVisible) {
                openShapeEditor(activeObject, canvasRef.current, { actionFrom: 'selection', isEdit: false });
            } else if (targetRef.current && overlappedObjects.length > 0) {
                closeTextEditor(false);

                // Need to render canvas due to unvisible textbox objects.
                activeObject.onShapeChanged()
                canvasRef.current.renderAll();
            }
        }
    }, [state.isVisible, closeTextEditor, openShapeEditor]);

    const closeTextEditorTemporarily = useCallback(() => {
        if (!state.isVisible) return;

        // Change opacity to zero suddenly in order to avoid from ui conflict issue. No need to change the opacity to one because editor will be re-opened.
        if (htmlEditorWrapperRef.current) {
            htmlEditorWrapperRef.current.style.opacity = 0;
        }

        // Close it via false. Otherwise, editor cannot be opened in quick rotation/scale changes.
        closeTextEditor(false);
    }, [state.isVisible, resetScrollPositionOfTextarea]);

    const handleBoardResize = useCallback(({ duration }) => {
        if (!state.isVisible) return;

        const target = targetRef.current;
        closeTextEditor();

        setTimeout(() => {
            openShapeEditor(target, canvasRef.current, { actionFrom: 'selection', isEdit: false });
        }, duration ?? 300);
    }, [state.isVisible, closeTextEditor, closeTextEditorTemporarily]);

    const onEditorFocused = useCallback((range, triggerer, actions) => {
        if (!state.target || state.isEdit) return;
        if (range.length === actions.getLength() - 1) return; // This check added because of some scenarios. When styles are updated while the editor is enabled but not in editing mode; we shouldn't lose the active selection of object.

        state.target.set({
            htmlMode: 'edit'
        });

        isEditorFocusedRef.current = true;
        // resetScrollPositionOfTextarea();

        dispatch({ type: HTML_EDITOR_ACTION_TYPES.EDITING_MODE_TURNED_ON });

        canvasRef.current?.requestRenderAll();
    }, [state.isEdit, state.isVisible, resetScrollPositionOfTextarea]);

    // Note: I couldn't find a scenario that fires this function. Because before editor on blur event is fired, we are removing editor from browser.
    // So this function is not fired. But I wanted to add below code-block just in case.
    const onEditorBlur = useCallback(() => {
        isEditorFocusedRef.current = false;
    }, []);

    const redirectMouseEventToCanvas = useCallback((e) => {
        const isZooming = e.type === 'wheel' && (e.ctrlKey || e.metaKey);
        const isEditorHovered = e.target && e.target.closest('.html-editor-wrapper');

        if (
            isEditorHovered &&
            !e.target.closest('.html-editor-btn') &&
            (e.type !== 'wheel' || isZooming)
        ) {
            e.preventDefault(); // Prevent default action
            e.stopPropagation(); // Stop the event from bubbling up

            const newMouseMoveEvent = new event.constructor(e.type, e);
            canvasRef.current.upperCanvasEl.dispatchEvent(newMouseMoveEvent);
        }
    }, []);

    const preventEditorTextDraggingEvent = useCallback((e) => {
        e.preventDefault();
    }, []);

    const handleDoubleClick = useCallback(({ event }) => {
        if (!state.isVisible) return;

        const editor = editorRef.current.getEditor();
        const [, textboxObject] = targetRef.current.getObjects();

        // We need to swap the textlines, because selected area may be in scrolled content.
        let selectionStart = 0;

        if (event?.e && textboxObject.text.length > 0) {
            const { charIndex } = HtmlEditorHelper.getSelectionByMousePos(event.e, {
                estimation: true,
                seamlessExperience: true,
                zoom: canvasRef.current?.getZoom()
            });

            selectionStart = charIndex;
        }

        editor.setSelection(selectionStart ?? 0, 0, 'silent');
        editor.scrollingContainer.scrollTop = scrollTopPos.current;
        editor.root.focus();

        // Trigger when selection is opened
        eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_SELECTION_UPDATED, { target: targetRef.current });
    }, [state.isVisible]);

    const completeContinuesScroll = () => {
        clearInterval(continuesScrollRef.current);
    }

    const handleScrollDown = () => {
        const scalingMode = HtmlEditorHelper.getScalingMode(canvasRef.current);

        // Setting variables
        let isAnimCompleted = false;
        continuesScrollRef.current = setInterval(() => {
            if (!editorRef.current) return completeContinuesScroll();
            const editor = editorRef.current.getEditor();
            let increaseValue = 1;
            const zoom = canvasRef.current?.getZoom() ?? 1;

            if (scalingMode === 'zoom' && zoom < 1) {
                increaseValue = 3;
            }

            const newScrollTop = Math.min(editor.scrollingContainer.scrollTop + increaseValue, editor.scrollingContainer.scrollHeight);

            const newOverflowStatus = { canScrollUp: true }    
            if (newScrollTop + editor.scrollingContainer.clientHeight >= editor.scrollingContainer.scrollHeight) {
                newOverflowStatus.canScrollDown = false;
                isAnimCompleted = true;
            }

            handleScrollPositionOfTextarea(newScrollTop);

            if (
                overflowStatusRef.current.canScrollUp !== newOverflowStatus.canScrollUp ||
                typeof newOverflowStatus.canScrollDown !== 'undefined'
            ) {
                dispatch({
                    type: HTML_EDITOR_ACTION_TYPES.OVERFLOW_STATUSES_UPDATED,
                    payload: newOverflowStatus
                });    
            }

            if (isAnimCompleted) {
                completeContinuesScroll();
            }
        }, 10)
    }

    const handleScrollUp = () => {
        let isAnimCompleted = false;

        continuesScrollRef.current = setInterval(() => {
            if (!editorRef.current) return completeContinuesScroll();
            const editor = editorRef.current.getEditor();
            const newScrollTop = Math.max(editor.scrollingContainer.scrollTop - 1, 0);

            // Scroll Up
            const newOverflowStatus = { canScrollDown: true }
            if (newScrollTop <= 0) {
                newOverflowStatus.canScrollUp = false;
                isAnimCompleted = true;
            }

            handleScrollPositionOfTextarea(newScrollTop);

            if (
                typeof newOverflowStatus.canScrollUp !== 'undefined' ||
                overflowStatusRef.current.canScrollDown !== newOverflowStatus.canScrollDown
            ) {
                dispatch({
                    type: HTML_EDITOR_ACTION_TYPES.OVERFLOW_STATUSES_UPDATED,
                    payload: newOverflowStatus
                });
            }

            if (isAnimCompleted) {
                completeContinuesScroll();
            }
        }, 10)
    }

    const handleMaxChars = useCallback((e) => {
        // Allow all shortcuts (e.g., Ctrl+C, Ctrl+V, Ctrl+Z, etc.)
        if (e.ctrlKey || e.metaKey) {
            return; // Do nothing and let the browser handle the shortcut
        }

        // Check for keys typically used for text insertion (letters, numbers, space, backspace, delete)
        const keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];
        const isTextKey = /^[a-zA-Z0-9\s]$/.test(e.key) || !keys.includes(e.key);
        
        const maxCharCount = SHAPE_DEFAULTS.MAX_CHAR_LENGTH_OF_EDITOR;
        const editor = editorRef.current.getEditor();
        const selection = editor.getSelection();
        let currentTextLength = editor.getLength();

        if (selection?.length >= 1) {
            currentTextLength -= selection?.length;
        }

        if (isTextKey && currentTextLength > maxCharCount) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }, []);

    const handleMaxCharsDuringPasting = useCallback((e) => {
        if (!state.isEdit) return;

        const text = (e.clipboardData || window.clipboardData).getData('text');
        const maxCharCount = SHAPE_DEFAULTS.MAX_CHAR_LENGTH_OF_EDITOR;

        const editor = editorRef.current.getEditor();
        let currentTextLength = editor.getLength() - 1;
        
        if (lastSelection.current && lastSelection.current?.length >= 1) {
            currentTextLength -= lastSelection.current?.length;
        }

        if (currentTextLength + text.length > maxCharCount) {
            e.preventDefault();
            const allowedText = text.slice(0, maxCharCount - currentTextLength);
            document.execCommand('insertText', false, allowedText);
        }
    }, [state.isEdit])

    useEffect(() => {
        eventEmitter.on(EMITTER_TYPES.OPEN_HTML_EDITOR, openTextEditor);
        eventEmitter.on(EMITTER_TYPES.CLOSE_HTML_EDITOR, closeTextEditor);
        eventEmitter.on(EMITTER_TYPES.HTML_EDITOR_STYLE_UPDATED, handleEditorStyles);
        eventEmitter.on(EMITTER_TYPES.HTML_EDITOR_HANDLE_DBL_CLICK, handleDoubleClick);
        eventEmitter.on(EMITTER_TYPES.HTML_EDITOR_DIMENSIONS_UPDATED, handleDimensionsUpdatedFlow);
        eventEmitter.on(EMITTER_TYPES.HTML_EDITOR_SEND_UPDATES, sendUpdatesManually);

        if (canvas) {
            canvas.on('board:pan', updateEditorPosition);
            canvas.on('board:zoom', onZoomChanged);
            canvas.on('mouse:wheel_from_minimap', onZoomChanged);
            canvas.on('mouse:move_from_minimap', updateEditorPosition);
            canvas.on('mouse:up_from_minimap', updateEditorPosition);
            canvas.on('mouse:down_from_minimap', updateEditorPosition);
            canvas.on('selection:created', closeTextEditor);
            canvas.on('selection:updated', closeTextEditor);
            canvas.on('selection:cleared', closeTextEditor);
            canvas.on('object:moving', closeTextEditorTemporarily);
            canvas.on('object:scaling', closeTextEditorTemporarily);
            canvas.on('object:skewing', closeTextEditorTemporarily);
            canvas.on('object:rotating', closeTextEditorTemporarily);
            canvas.on('board:resized', handleBoardResize);
            canvas.on('object:modified', handleOverlapping);
            canvas.on('z-indexes-updated', handleOverlapping);
        }

        return () => {
            eventEmitter.off(EMITTER_TYPES.OPEN_HTML_EDITOR, openTextEditor);
            eventEmitter.off(EMITTER_TYPES.CLOSE_HTML_EDITOR, closeTextEditor);
            eventEmitter.off(EMITTER_TYPES.HTML_EDITOR_STYLE_UPDATED, handleEditorStyles);
            eventEmitter.off(EMITTER_TYPES.HTML_EDITOR_HANDLE_DBL_CLICK, handleDoubleClick);
            eventEmitter.off(EMITTER_TYPES.HTML_EDITOR_DIMENSIONS_UPDATED, handleDimensionsUpdatedFlow);
            eventEmitter.off(EMITTER_TYPES.HTML_EDITOR_SEND_UPDATES, sendUpdatesManually);

            if (canvas) {
                canvas.off('board:pan', updateEditorPosition);
                canvas.off('board:zoom', onZoomChanged);
                canvas.off('mouse:wheel_from_minimap', onZoomChanged);
                canvas.off('mouse:move_from_minimap', updateEditorPosition);
                canvas.off('mouse:up_from_minimap', updateEditorPosition);
                canvas.off('mouse:down_from_minimap', updateEditorPosition);
                canvas.off('selection:created', closeTextEditor);
                canvas.off('selection:updated', closeTextEditor);
                canvas.off('selection:cleared', closeTextEditor);
                canvas.off('object:moving', closeTextEditorTemporarily);
                canvas.off('object:scaling', closeTextEditorTemporarily);
                canvas.off('object:skewing', closeTextEditorTemporarily);
                canvas.off('object:rotating', closeTextEditorTemporarily);
                canvas.off('board:resized', handleBoardResize);
                canvas.off('object:modified', handleOverlapping);
                canvas.off('z-indexes-updated', handleOverlapping);
            }
        }
    }, [openTextEditor, canvas, onZoomChanged, handleDoubleClick, closeTextEditorTemporarily, handleOverlapping, handleDimensionsUpdatedFlow, sendUpdatesManually, handleBoardResize]);

    useEffect(() => {
        if (state.isVisible && editorRef.current) {
            let events = ['mouseover', 'mousedown', 'mouseup', 'wheel', 'dblclick', 'dragstart', 'dragover', 'dragenter', 'dragleave', 'drop'];

            if (state.isEdit === true) {
                events = ['wheel'];
            }

            events.forEach((eventName) => {
                htmlEditorWrapperRef.current.addEventListener(eventName, redirectMouseEventToCanvas);
            });

            return () => {
                if (htmlEditorWrapperRef.current) {
                    events.forEach((eventName) => {
                        htmlEditorWrapperRef.current.removeEventListener(eventName, redirectMouseEventToCanvas);
                    });
                }
            }
        }
    }, [state.isVisible, state.isEdit, redirectMouseEventToCanvas]);

    useEffect(() => {
        initQuill();
    }, [initQuill]);

    useLayoutEffect(() => {
        if (state.isVisible === true && editorRef.current) {
            HtmlEditorHelper.setEditor(editorRef, state.target, htmlEditorWrapperRef, { downArrowButtonRef, upArrowButtonRef });
            eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_INITALIZED);
        }

        return () => {
            HtmlEditorHelper.removeEditor();
        }
    }, [state.isVisible, canvas]);

    useEffect(() => {
        if (state.isVisible) {
            let textboxObject = null;

            // Initial Values
            updateEditorPosition();
            handleEditorStyles();
            updateArrowSizes();

            if (state.target?.type === 'group') {
                textboxObject = targetRef.current.getObjects()[1];
            }

            initialValueRef.current = getDeltaValueOfTextbox(targetRef.current, textboxObject);

            if (callbackFuncRef.current) {
                callbackFuncRef.current();
            }
        }
    }, [state.isVisible, state.isEdit]);

    useEffect(() => {
        if (state.isVisible && editorRef.current) {
            const editor = editorRef.current.getEditor();

            // Handling on paste scenarios. Font size and text align should be global properties so it shouldn't be set text.
            editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                const newDelta = delta.ops.map((op) => {
                    if (op.attributes) {
                        delete op.attributes.font;
                        delete op.attributes.align;
                    }

                    return {
                        insert: op.insert,
                        attributes: op.attributes
                    }
                });

                const Delta = Quill.import('delta');
                return new Delta(newDelta)
            })
        }
    }, [state.isVisible]);

    useEffect(() => {
        updateEditorPosition();
        updateArrowSizes();
    }, [state.overflowStatus.canScrollDown, state.overflowStatus.canScrollUp, updateEditorPosition, updateArrowSizes]);

    useEffect(() => {
        if (!state.isVisible) return;
        if (!state.isEdit) return;

        HtmlEditorHelper.startEditingForLockManager(
            targetRef.current,
            canvasRef.current,
            abortTextEditing
        );
    }, [state.isVisible, state.isEdit, abortTextEditing]);

    useEffect(() => {
        if (!state.isEdit) return;
        if (!targetRef.current) return;

        const target = targetRef.current;
        let delta = null;

        if (target.type === 'group') {
            const textboxObject = target.getObjects()[1];
            delta = getDeltaValueOfTextbox(target, textboxObject);
        }
    
        if (delta) {
            initialValueRef.current = delta;
            setValue(delta);
        }
    }, [state.isEdit]);

    useEffect(() => {
        if (!state.isVisible || !state.isEdit) return;
        if (!targetRef.current) return;
        const threshold = 1;
        const editor = editorRef.current.getEditor();
        const isOverflowExist = editor.scrollingContainer.scrollHeight - editor.scrollingContainer.clientHeight > threshold;

        if (state.isTextOverflow !== isOverflowExist) {
            dispatch({
                type: HTML_EDITOR_ACTION_TYPES.TEXT_OVERFLOW_STATUS_CHANGED,
                payload: {
                    checkManual: true,
                    isTextOverflow: isOverflowExist
                }
            });
        }
    }, [state.isVisible, state.isEdit, state.isTextOverflow, value]);

    useLayoutEffect(() => {
        if (!state.isVisible) return;

        const el = canvasRef.current.upperCanvasEl;
        const resizeObserver = new ResizeObserver(() => {
            updateEditorPosition();
        });

        resizeObserver.observe(el);
    }, [state.isVisible, updateEditorPosition]);

    useEffect(() => {
        if (!state.isVisible) return;
        if (!editorRef.current) return;

        const wrapper = htmlEditorWrapperRef.current;
        const editor = editorRef.current.getEditor();

        // Handle Dimensions first
        Object.entries({ ...dimensionsRef.current }).forEach(([key, value]) => {
            wrapper.style[key] = value;
        });

        Object.entries({ ...editorStylesRef.current }).forEach(([key, value]) => {
            editor.root.style[key] = value;
        });

        const selection = editor.getSelection();
        // If scroll positions are not same, update editor scroll value.
        if (scrollTopPos.current !== editor.scrollingContainer.scrollTop && selection === null) {
            editor.scrollingContainer.scrollTop = scrollTopPos.current;
        }
    });

    useEffect(() => {
        if (!state.isVisible) return;
        if (!editorRef.current) return;

        const editor = editorRef.current.getEditor();
        if (state.isEdit) {
            editor.root.addEventListener('scroll', handleEditorScroll);
            editor.root.addEventListener('wheel', handleHorizontalScroll);
            editor.root.addEventListener('keydown', handleMaxChars, { capture: true }); // Capture is for prioritize
            editor.root.addEventListener('paste', handleMaxCharsDuringPasting);
        } else {
            editor.root.addEventListener('wheel', handleEditorScroll);
        }

        // To prevent firefox text dragging.
        editor.root.addEventListener('dragstart', preventEditorTextDraggingEvent);

        return () => {
            editor.root.removeEventListener('scroll', handleEditorScroll);
            editor.root.removeEventListener('wheel', handleEditorScroll);
            editor.root.removeEventListener('wheel', handleHorizontalScroll);
            editor.root.removeEventListener('dragstart', preventEditorTextDraggingEvent);
            editor.root.removeEventListener('paste', handleMaxCharsDuringPasting);
            editor.root.removeEventListener('keydown', handleMaxChars);
        }
    }, [state.isVisible, state.isEdit, handleEditorScroll, handleHorizontalScroll, preventEditorTextDraggingEvent, handleMaxCharsDuringPasting, handleMaxChars]);

    // Reset the editor history. Otherwise, users can remove the content after editor is initalized.
    useEffect(() => {
        if (!state.isVisible) return;
        if (!state.isEdit) return;
        if (!editorRef.current) return;

        const editor = editorRef.current.getEditor();
        editor.history.clear(); // Clear undo history
    }, [state.isVisible, state.isEdit]);

    // Editor shouldn't be rendered again for other unrelated renders.
    const EditorDOM = useMemo(() => {
        return (
            <ReactQuill
                className="html-editor"
                value={value}
                setValue={setValue}
                onChange={onEditorChanged}
                onChangeSelection={onSelectionUpdated}
                onFocus={onEditorFocused}
                onBlur={onEditorBlur}
                ref={editorRef}
                formats={formats}
                modules={modules}
            />
        )
    }, [value, formats, modules, onEditorChanged, onSelectionUpdated, onEditorFocused, onEditorBlur])

    if (state.isVisible !== true) {
        return null;
    }

    return (
        <div
            className="html-editor-wrapper"
            data-is-edit={state.isEdit.toString()}
            data-text-overflow={state.isTextOverflow.toString()}
            ref={htmlEditorWrapperRef}
        >
            {EditorDOM}

            <div
                className="actions editor-action-buttons"
                hidden={state.isEdit ? (state.isEdit || state.isTextOverflow) : !state.isTextOverflow}
            >
                <button
                    ref={upArrowButtonRef}
                    type="button"
                    role="button"
                    className="html-editor-btn up-btn"
                    onMouseDown={handleScrollUp}
                    onMouseUp={completeContinuesScroll}
                    onMouseLeave={completeContinuesScroll}
                    hidden={!state.overflowStatus.canScrollUp}
                >
                    <ArrowUpCircle />
                </button>

                <button
                    ref={downArrowButtonRef}
                    type="button"
                    role="button"
                    className="html-editor-btn down-btn"
                    onMouseDown={handleScrollDown}
                    onMouseUp={completeContinuesScroll}
                    onMouseLeave={completeContinuesScroll}
                    hidden={!state.overflowStatus.canScrollDown}
                >
                    <ArrowDownCircle />
                </button>
            </div>
        </div>
    )
};

HtmlEditor.propTypes = {
    canvas: PropTypes.object.isRequired,
    formats: PropTypes.arrayOf(PropTypes.string),
    modules: PropTypes.shape({
        toolbar: PropTypes.shape({
            container: PropTypes.string.isRequired,
            size: PropTypes.arrayOf(PropTypes.number).isRequired,
            font: PropTypes.string.isRequired
        }).isRequired,
        clipboard: {
            matchVisual: false, // Disable Quill's automatic style matching on paste
        },
    }).isRequired
}

HtmlEditor.defaultProps = {
    formats: ['font', 'size', 'bold', 'italic', 'underline', 'link', 'color', 'align', 'background'],
    modules: {
        toolbar: {
            container: '.toolControls',
            size: TEXT_SIZE_OPTIONS.map((size) => `${size}px`),
            font: ['Rubik,sans-serif']
        }
    }
}

export default memo(HtmlEditor);