
export function extendShortenedText() {
    const temp = {
        lines: this.textLines,
        graphemeLines: this._textLines
    }

    this.textLines = this.textLinesWithoutLimit;
    this._textLines = this._textLinesWithoutLimit;

    this._styleMap = this._generateStyleMap({
        _unwrappedTextLines: this._unwrappedTextLines,
        lines: this.textLines,
        graphemeText: this._text,
        graphemeLines: this._textLines
    });

    return () => {
        this.textLines = temp.lines;
        this._textLines = temp.graphemeLines;

        this._styleMap = this._generateStyleMap({
            _unwrappedTextLines: this._unwrappedTextLines,
            lines: this.textLines,
            graphemeText: this._text,
            graphemeLines: this._textLines
        });
    }
}