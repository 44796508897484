import { TEXT_SIZE_OPTIONS } from '../Constant';

export const SHAPES = {
    LINE: 'line',
    RECTANGLE: 'rectangle',
    CIRCLE: 'circle',
    TEXT: 'text',
    STICKY_NOTE: 'stickyNote',
    IMAGE: 'image',
    PEN: 'pen',
    FRAME: 'frame',
    COMPOSITE: 'composite',  // (group object) (for tables only)
    COMMENT: 'comment'
}

export const COMMANDS = {
    MOVE: 'move',
    UNDO: 'undo',
    RESIZE: 'resize',
    COLOR_MODIFY: 'colorModifier',
    STICKY_NOTE_MODIFIER: 'stickyNoteModifier',
    SIZE_MODIFIER: 'sizeModifier',
    TEXT_MODIFIER: 'textModifier',
    FONT_SIZE_MODIFIER: 'fontSizeModifier',
    LOCK: 'lock',
    REMOVE: 'remove',
    COMMAND_SET: 'commandSet',
    REPOSITION: 'reposition',
    CHANGE_ORDER: 'changeOrder',
    PROPERTY_MODIFIER: 'propertyModifier',
    REPLACE: 'replace',
}

// Nima board holds the comment icon colors as numbers
export const NIMA_COMMENT_COLORS = {
    '1': 'blue',
    '2': 'orange',
    '3': 'red,',
    '4': 'green',
    '5': 'purple',
    '6': 'purple' // since we don't support black, make it purple
}


export const VALID_SHAPES = Object.values(SHAPES)
export const VALID_COMMANDS = Object.values(COMMANDS)

export const RECTANGULAR_SHAPES = [SHAPES.RECTANGLE, SHAPES.FRAME, SHAPES.STICKY_NOTE, SHAPES.IMAGE]

export const THIRD_PARTY_FONTS = [
    {
        name: 'Open Sans',
        url: '/nimaFonts/OpenSans-Regular.ttf'
    },
    {
        name: 'Arial'
    },
    {
        name: 'Courier Prime',
        url: '/nimaFonts/Courier-Prime-Sans.ttf'
    },
    {
        name: 'Caveat',
        url: '/nimaFonts/Caveat-Regular.ttf'
    },
    {
        name: 'Tahoma'
    },
    {
        name: 'Cabin Sketch',
        url: '/nimaFonts/CabinSketch-Bold.ttf'
    },
    {
        name: 'Verdana'
    },
    {
        name: 'Montserrat',
        url: '/nimaFonts/Montserrat-Regular.ttf'
    },
    {
        name: 'Georgia'
    },
    {
        name: 'Comic Sans MS'
    },
    {
        name: 'Times New Roman'
    }
]

export const FONT_SIZE_CONSTRAINTS = {
    MIN: TEXT_SIZE_OPTIONS[0],
    MAX: TEXT_SIZE_OPTIONS[TEXT_SIZE_OPTIONS.length - 1]
}

export const NIMA_COMMENT_TAG_REGEX = /\[(.*?)\]\((.*?)\)/g