
export const classNames = (classes) => {
    if (!classes || typeof classes !== 'object') { return classes; }

    let className = '';
    
    Object.entries(classes).forEach(([key, value]) => {
        if (value === true) {
            className += `${key} `;
        }
    });

    return className;
}

export const getHtmlElementScaling = (element) => {
    const style = window.getComputedStyle(element);
    const transform = style.transform || style.webkitTransform || style.mozTransform;

    if (transform === 'none' || !transform) {
        return { scaleX: 1, scaleY: 1 };  // No scaling applied
    }

    // Matrix looks like: matrix(a, b, c, d, e, f) or matrix3d(a, b, c, d, e, f, ..., m)
    const matrixValues = transform?.match(/matrix\(([^)]+)\)/);

    if (matrixValues) {
        const values = matrixValues[1].split(',').map(Number);
        const scaleX = values[0];
        const scaleY = values[3];
        return { scaleX, scaleY };
    }

    return { scaleX: 1, scaleY: 1 };  // Default scaling
}