/**
 * Exits from editing state.
 * @override
 * @returns {fabric.IText} ThisArg.
 * @chainable
 */
export function exitEditing() {
    var isTextChanged = (this._textBeforeEdit !== this.text);
    var hiddenTextarea = this.hiddenTextarea;
    this.selected = false;
    this.isEditing = false;

    this.selectionEnd = this.selectionStart;

    if (hiddenTextarea) {
        hiddenTextarea.blur && hiddenTextarea.blur();
        hiddenTextarea.parentNode && hiddenTextarea.parentNode.removeChild(hiddenTextarea);
    }
    this.hiddenTextarea = null;
    this.abortCursorAnimation();
    this._restoreEditingProps();
    this._currentCursorOpacity = 0;
    if (this._shouldClearDimensionCache()) {
        this.initDimensions();
        this.setCoords();
    }
    this.fire('editing:exited');
    isTextChanged && this.fire('modified');
    // @overrided content.
    let canvas = this.canvas;
    if (!canvas && this.isTableTextbox && this.tableObject) {
        canvas = this.tableObject.canvas;
    }

    if (canvas) {
        canvas.off('mouse:move', this.mouseMoveHandler);
        canvas.fire('text:editing:exited', { target: this });
        (isTextChanged && !this.isAbortedEditing) && canvas.fire('object:modified', { target: this, processId: this.editingProcessId });
        if (this.editingProcessId) {
            this.canvas.collaborationManager.completeContinuousEditing(this.editingProcessId)
            this.editingProcessId = null;
        }
    }
    this.editingStarted = false;
    return this;
}