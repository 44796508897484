import { fabric } from 'fabric';
import HtmlEditorHelper from '../../HtmlEditorHelper';

function getRenderableLinesCount(textLines) {
    let renderableLinesCount = textLines.length;

    if (this.group) {
        renderableLinesCount = 0;
        this._textLinesWithoutLimit = textLines;
        const heightOfLine = this.fontSize * this.lineHeight * this._fontSizeMult;

        // Calculate the text area dimensions
        const { height } = HtmlEditorHelper.calculateHtmlEditorDimensions(this.group, this.group?.canvas, { absolute: false, overflowStatus: {} });
        const shapeHeight = height / this.group?.scaleY;

        let currentHeight = 0;
        // Calculating the maximum lines that we can render
        for (let i = 0; i < textLines.length; i++) {
            if (Math.ceil(currentHeight + heightOfLine) >= shapeHeight) break;
            renderableLinesCount += 1;
            currentHeight += heightOfLine;
        }
    }

    return Math.max(renderableLinesCount, 1);
}

export function _splitTextIntoLines(text, isTextShortable = true) {
    let newText = fabric.Text.prototype._splitTextIntoLines.call(this, text),
        graphemeLines = this._wrapText(newText.lines, this.width),
        lines = new Array(graphemeLines.length);
    let renderableLinesCount = getRenderableLinesCount.call(this, graphemeLines);

    let temp = {}

    if (isTextShortable && renderableLinesCount !== lines.length) {
        temp = { graphemeLines, lines }

        for (let i = 0; i < graphemeLines.length; i++) {
            temp.lines[i] = temp.graphemeLines[i].join('');
        }

        graphemeLines = graphemeLines.slice(0, renderableLinesCount);
        lines = new Array(graphemeLines);
    }

    for (let i = 0; i < graphemeLines.length; i++) {
        lines[i] = graphemeLines[i].join('');
    }

    newText.lines = lines;
    newText.graphemeLines = graphemeLines;

    if (Array.isArray(temp.lines)) {
        newText.linesWithoutLimit = temp.lines;
        newText.graphemeLinesWithoutLimit = temp.graphemeLines;
    }

    return newText;
}