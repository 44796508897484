import { isTargetIncludeText } from '../FabricMethods';
import HtmlEditorHelper from '../../helpers/HtmlEditorHelper';

const getTextObjectFromTarget = (target) => {
    if (isTargetIncludeText(target)) {
        return target._objects.find((obj) => obj.type === 'textbox');
    } else if (target.type === 'textbox') {
        return target;
    } else if (target.type === 'table' && target.isCellSelected) {
        return target.getSelectedCell();
    }
    return null;
}

export const getActiveFontStyle = (target) => {
    let  isBold = true;
    let isItalic = true;
    let isUnderline = true;

    if (target.isHtmlEditingMode) {
        const styles = HtmlEditorHelper.getSelectionStyles();

        return {
            bold: styles?.bold ?? false,
            italic: styles?.italic ?? false,
            underline: styles?.underline ?? false,
        }
    }

    const textObject = getTextObjectFromTarget(target);
    
    if (textObject && textObject.styles) {
        const styleWrapperValues = Object.values(textObject.styles);
        for (const styleWrapper of styleWrapperValues) {
            for (const style of Object.values(styleWrapper)) {
                if (style.fontWeight !== 'bold') {
                    isBold = false;
                } 
                if (style.fontStyle !== 'italic') {
                    isItalic = false;
                } 
                if (!style.underline) {
                    isUnderline = false;
                }
            }
        }
        if (styleWrapperValues.length === 0) {
            isBold = false;
            isItalic = false;
            isUnderline = false;
        }

        return {
            bold: isBold,
            italic: isItalic,
            underline: isUnderline,
        }
    }
    return {
        bold: false,
        italic: false,
        underline: false
    };
}


export const getActiveTextAlign = (target) => {
    const textObject = getTextObjectFromTarget(target);
    return textObject ? textObject.textAlign : null;
}


export const getRgbaVal = (colorRgba) => {
    try {
        const regExp = /\(([^)]+)\)/;
        const matches = regExp.exec(colorRgba);
        let val = matches ? matches[1]?.split(',') : [0,0,0];
        
        return {
            r: parseInt(val[0]),
            g: parseInt(val[1]),
            b: parseInt(val[2]),
            a: isNaN(parseFloat(val[3])) ? 1 : parseFloat(val[3]),
        }
    } catch (err) {
        console.error('error while getting rgba of ', colorRgba, 'error => ', err);
    }
    return null;
}