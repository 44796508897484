
/**
 * @private
 * Divides text into lines of text and lines of graphemes.
 */
export function _splitText(isTextShortable = true) {
    var newLines = this._splitTextIntoLines(this.text, isTextShortable);
    this.textLines = newLines.lines;
    this._textLines = newLines.graphemeLines;

    if (newLines.linesWithoutLimit) {
        this.textLinesWithoutLimit = newLines.linesWithoutLimit;
        this._textLinesWithoutLimit = newLines.graphemeLinesWithoutLimit;
    }

    this.isTextShortened = typeof newLines.linesWithoutLimit !== 'undefined' && (newLines.linesWithoutLimit !== newLines.lines);

    this._unwrappedTextLines = newLines._unwrappedLines;
    this._text = newLines.graphemeText;
    return newLines;
}