import { fabric } from 'fabric';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';
import { SHAPE_DEFAULTS } from '../../helpers/Constant';
import { rotatePointBack } from '../../helpers/lines/AttachedObjectTransformHandlers';
import { rotatePoint } from '../../helpers/FabricMethods';
import { useSelector } from 'react-redux';
import StickyOwner from '../../helpers/sticky/DrawStickyOwner';

const GlobalTooltip = ({
    canvas
}) => {
    const users = useSelector((state) => state?.board?.users);

    const [state, setState] = useState({
        toolipText: '',
        placement: 'top',
        isVisible: false,
        dimensions: {}
    });

    const getStickyNoteOwnerName = useCallback((userId) => {
        if (!Array.isArray(users)) { return null; }
        return users.find((user) => user.id === userId)?.name;
    }, [users]);

    const isContainedWithinPoint = useCallback((coords, pointer, target) => {
        const _pointer = target.angle !== 0 ? rotatePointBack(
            pointer.x,
            pointer.y,
            target.left,
            target.top,
            target.angle
        ) : pointer;
        
        if (
            _pointer.x >= coords.tl.x &&
            _pointer.x <= coords.tr.x &&
            _pointer.y >= coords.tr.y &&
            _pointer.y <= coords.br.y
        ) {
            return true;
        }

        return false;
    }, []);

    const handleStickyNoteOwnerName = useCallback((target, pointer) => {
        const ownerName = target?.ownerName ?? getStickyNoteOwnerName(target.createdBy);
        let coords = {}

        // We will rotate back the coordinates and pointer itself. So we can compare.
        if (target.angle !== 0) {
            coords = {
                tl: rotatePointBack(target.aCoords.tl.x, target.aCoords.tl.y, target.left, target.top, target.angle),
                tr: rotatePointBack(target.aCoords.tr.x, target.aCoords.tr.y, target.left, target.top, target.angle),
                br: rotatePointBack(target.aCoords.br.x, target.aCoords.br.y, target.left, target.top, target.angle),
                bl: rotatePointBack(target.aCoords.bl.x, target.aCoords.bl.y, target.left, target.top, target.angle),
            }
        } else {
            coords = target.aCoords;
        }

        // We are only checking the bottom side (owner name) area. So update top points.
        coords.tl.y = coords.bl.y - SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT;
        coords.tr.y = coords.br.y - SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT;

        const isContained = isContainedWithinPoint(coords, pointer, target);
        const zoom = canvas.getZoom();

        // Finding actual points in page.
        let tooltipCoordinates = {
            x: (coords.bl.x + coords.br.x) / 2,
            y: coords.bl.y
        };

        if (target.angle !== 0) {
            tooltipCoordinates = rotatePoint(
                tooltipCoordinates.x,
                tooltipCoordinates.y,
                target.left,
                target.top,
                target.angle
            );
        }

        let { x: left, y: top } = fabric.util.transformPoint(new fabric.Point(
            tooltipCoordinates.x,
            tooltipCoordinates.y
        ), canvas.viewportTransform, false);

        top -= SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT / 2 * zoom;

        const isNameOverflowed = StickyOwner.isOwnerNameOverflowed(ownerName, target.width);
        if (isContained && ownerName && isNameOverflowed) {
            setState({
                placement: 'over',
                toolipText: ownerName,
                dimensions: { left, top },
                isVisible: true
            });
        } else if (state.isVisible === true) {
            setState((prevState) => ({
                ...prevState,
                isVisible: false
            }));
        }
    }, [isContainedWithinPoint, getStickyNoteOwnerName, canvas, state.isVisible]);

    const catchMouseOver = useCallback((event) => {
        const { target } = event;
        if (!target) {
            if (state.isVisible === true) {
                setState((prevState) => ({
                    ...prevState,
                    isVisible: false
                }));
            }

            return;
        }

        const pointer = canvas.getPointer(event.e, false);

        if (target.type === 'group' && target.shapeType === 'sticky') {
            handleStickyNoteOwnerName(target, pointer, event);
        }
    }, [handleStickyNoteOwnerName, canvas, state.isVisible]);
    
    useEffect(() => {
        if (!canvas) return;
        canvas.on('tooltip:mousemove', catchMouseOver);
        canvas.on('mouse:wheel', catchMouseOver);

        return () => {
            canvas.off('tooltip:mousemove', catchMouseOver);
            canvas.off('mouse:wheel', catchMouseOver);
        }
    }, [canvas, catchMouseOver]);

    if (!state.isVisible) {
        return null;
    }

    return (
        <Tooltip
            className="_v2"
            text={state.toolipText}
            dimensions={state.dimensions}
            position={state.placement}
            usePortal={true}
        />
    )
}

GlobalTooltip.propTypes = {
    canvas: PropTypes.object.isRequired,
}

export default GlobalTooltip;