import { fabric } from 'fabric';
import { SHAPE_DEFAULTS, EDITING_METHODS } from './Constant';
import { calculateIntersectionOfTwoLines } from './FabricMethods';
import { rotatePointBack } from './lines/AttachedObjectTransformHandlers';
import { getHtmlElementScaling } from './Dom';
import { getBrowserName } from './BrowserInfo';

class HtmlEditorHelper {
    editorInstance = null;
    wrapperInstance = null;
    fabricObject = null;
    arrowRefs = {};
    fabricFormats = { fontWeight: 'bold', fontStyle: 'italic' }

    constructor() {}

    _turnOffSpellCheck() {
        this.editorInstance.editor.root.setAttribute('spellcheck', 'false');
    }

    isObjectAllowedToHtmlEditor(object) {
        return object?.type === 'group';
    }

    isHtmlEditorEnabled() {
        const wrapper = this.getWrapperInstance();
        return !!wrapper;
    }

    isEditMode() {
        const wrapper = this.getWrapperInstance();
        if (!wrapper) return false;
        return wrapper.getAttribute('data-is-edit') === 'true';
    }

    getScalingMode(canvas) {
        const browserName = getBrowserName();
        const target = this.getFabricObject();
        canvas = canvas ?? target?.canvas;

        if (!target) {
            return browserName === 'Safari' ? 'zoom' : 'scale';
        }

        if (browserName === 'Safari') {
            const zoom = canvas?.getZoom() ?? 1;
            const textbox = target.getObjects().find((obj) => obj.type === 'textbox');

            // Safari has a setting "Never use font sizes smaller than". Its default 9px. So use scaling mode instead of zoom if calculated font size smaller than 10px. (threshold.)
            return (textbox.fontSize * target.scaleX * zoom <= 10) ? 'scale' : 'zoom';
        }

        return 'scale';
    }

    getScrollTop() {
        const editor = this.editorInstance.getEditor();
        if (!editor) return 0;
        return editor.root.scrollTop;
    }

    _getArrowSizeForCalculateDimension(zoom) {
        let arrowSize = 24;
        let arrowMargin = 3;
        const { downArrowButtonRef, upArrowButtonRef } = this.arrowRefs;
        let arrowBtn = null;
        
        if (upArrowButtonRef?.current && upArrowButtonRef.current?.hidden !== true) {
            arrowBtn = upArrowButtonRef.current;
        } else if (downArrowButtonRef?.current && downArrowButtonRef.current?.hidden !== true) {
            arrowBtn = downArrowButtonRef.current;
        }

        if (!arrowBtn || zoom < 1) {
            return { arrowMargin, arrowSize }
        }

        const scaling = getHtmlElementScaling(arrowBtn)
        const scale = scaling.scaleX ?? 1;

        return {
            arrowSize: parseFloat(getComputedStyle(arrowBtn).height.slice(0, -2)) * scale,
            arrowMargin: arrowMargin * scale
        }
    }

    getEditorHeight() {
        const editor = this.getEditor();
        const height = editor.root.clientHeight;
        const padding = parseFloat(getComputedStyle(editor.root)['padding'].slice(0, -2));
        return height - (padding * 2);
    }

    _getCharacterBoundingBoxes(options = {}) {
        const editor = this.getEditor();
        let boundingBoxes = [];
        let charIdx = 0;
        let lineIndex = 0;  // Initialize lineIndex

        function traverseNodes(node, isRootLevel) {
            if (node.nodeType === Node.TEXT_NODE) {
                const textLength = node.textContent.length;
                const range = document.createRange();

                // Loop through each character in the text node
                for (let i = 0; i < textLength; i++) {
                    range.setStart(node, i);
                    range.setEnd(node, i + 1);

                    const rect = range.getBoundingClientRect();

                    boundingBoxes.push({
                        char: node.textContent[i],
                        node: node,
                        index: charIdx,
                        lineIndex,
                        bounds: {
                            top: rect.top,
                            bottom: rect.bottom,
                            left: options?.seamlessExperience ? rect.left - rect.width / 2 : rect.left,
                            right: options?.seamlessExperience ? rect.right - rect.width / 2 : rect.right,
                            width: rect.width,
                            height: rect.height,
                        }
                    });

                    // Update the lastTop value
                    charIdx++;
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                // Recursively traverse child nodes if it's an element
                node.childNodes.forEach((child) => traverseNodes(child));
            }

            if (isRootLevel) {
                const rect = node.getBoundingClientRect();
                const lastCharBounds = boundingBoxes[boundingBoxes.length - 1]?.bounds;
                const isBrTag = node?.childNodes?.[0]?.tagName === 'BR';

                boundingBoxes.push({
                    char: '\n',
                    node: node,
                    index: charIdx,
                    lineIndex,
                    bounds: {
                        top: isBrTag ? rect?.top : lastCharBounds?.top,
                        bottom: isBrTag ? rect?.bottom : lastCharBounds?.bottom,
                        left: lastCharBounds?.right + 1,
                        right: lastCharBounds?.right + 1,
                        width: 0,
                        height: lastCharBounds?.height,
                    }
                });

                charIdx++;
                lineIndex +=1;
            }
        }

        // traverseNodes(editor.root);
        editor.root.childNodes.forEach((child) => {
            traverseNodes(child, true);
        });

        // Sometimes, safari doesn't calculate bounding box correct. We need to correct those incorrect calculations.
        if (getBrowserName() === 'Safari') {
            for (let i = 0; i < boundingBoxes.length - 1; i++) {
                const { bounds } = boundingBoxes[i];
                const { bounds: nextBounds } = boundingBoxes[i + 1];

                if (bounds.height / nextBounds.height >= 2) {
                    boundingBoxes[i].bounds = {
                        ...boundingBoxes[i].bounds,
                        top: nextBounds.top,
                        bottom: nextBounds.bottom,
                        height: nextBounds.height,
                        left: nextBounds.left - nextBounds.width,
                        right: nextBounds.left,
                        width: nextBounds.width
                    }
                }
            }
        }

        // Due to differences between line height and font size, there are some minor gap diffs occurred. To detect the cursor properly, find the actual top and bottom points.
        if (boundingBoxes.length > 0 && options?.estimation === true) {
            let lineHeight = this.getLineHeight();

            if (options.scalingMode === 'scale') {
                const zoom = options?.zoom ?? 1;
                lineHeight *= zoom;
            }

            boundingBoxes = boundingBoxes.map((item) => {
                const bboxHeight = item.bounds.bottom - item.bounds.top;
                const diff = Math.abs(lineHeight - bboxHeight) / 2;

                return {
                    ...item,
                    bounds: {
                        ...item.bounds,
                        top: item.bounds.top - diff,
                        bottom: item.bounds.bottom + diff,
                    }
                }
            })
        }

        return boundingBoxes;
    }

    getSelectionByMousePos(e, options = {}) {
        const zoom = options?.zoom ?? 1;
        const wrapper = this.getWrapperInstance();
        const fabricObject = this.getFabricObject();
        const editor = this.getEditor();
        const editorContainer = editor.container;
        const angle = fabricObject?.angle;
        let scalingMode = this.getScalingMode();
        let result = { charIndex: null, bounds: {} }
        let editorBounds = null;

        let originalTransform = null;
        let mousePos = { x: e.clientX, y: e.clientY };

        if (scalingMode === 'zoom') {
            mousePos.x /= zoom;
            mousePos.y /= zoom;
        }

        if (angle !== 0) {
            // Save the current transform style
            originalTransform = wrapper.style.transform;

            // Temporarily remove the rotate transformation
            wrapper.style.transform = originalTransform.replace(/rotate\([^)]*\)/, '');

            editorBounds = editor.root.getBoundingClientRect();
            const cx = editorBounds.left + (editorBounds.width / 2);
            const cy = editorBounds.top + (editorBounds.height / 2);

            mousePos = rotatePointBack(mousePos.x, mousePos.y, cx, cy, angle);
        } else {
            editorBounds = editor.root.getBoundingClientRect();
        }

        const charBounds = this._getCharacterBoundingBoxes({
            estimation: options?.estimation,
            seamlessExperience: options?.seamlessExperience,
            scalingMode,
            zoom
        });

        const verticallyMatchedBounds = [];

        for (let i = 0; i < charBounds.length; i++) {
            const { bounds, char, index } = charBounds[i];
            const { bounds: nextCharBounds } = charBounds[Math.min(i + 1, charBounds.length - 1)];

            const isHorizontallyMatched = mousePos.x >= bounds.left && mousePos.x <= bounds.right;
            const isVerticallyMatched = mousePos.y >= bounds.top && mousePos.y <= bounds.bottom;
            const isLineWrap = bounds?.top !== nextCharBounds?.top;

            let charIndex = index;

            if (isHorizontallyMatched && isVerticallyMatched) {
                result = {
                    charIndex,
                    bounds
                }

                break;
            } else if (isVerticallyMatched && options.estimation === true) {
                verticallyMatchedBounds.push({
                    idx: charIndex,
                    char,
                    isLineWrap,
                    bounds
                })
            }

            if (mousePos.y < bounds.top) {
                break;
            }
        }

        if (result?.charIndex === null && options.estimation === true && verticallyMatchedBounds.length > 0)  {
            const diffs = [];

            for (let item of verticallyMatchedBounds) {
                const { idx, char, bounds, isLineWrap } = item;

                const distanceLeft = Math.abs(bounds.left - mousePos.x);
                const distanceRight = Math.abs(bounds.right - mousePos.x);

                const charIndex = distanceRight < distanceLeft && isLineWrap ? idx + 1 : idx;

                diffs.push({
                    char,
                    idx: charIndex,
                    bounds,
                    distance: Math.min(distanceLeft, distanceRight)
                });
            }

            diffs.sort((a,b) => {
                return a.distance - b.distance;
            });

            result = { charIndex: diffs[0].idx, bounds: diffs[0].bounds }
        }

        if (options.estimation === true && result?.charIndex === null) {
            const lastChar = charBounds[charBounds.length - 1];

            if (mousePos.y >= lastChar?.bounds?.top) {
                result = {
                    charIndex: lastChar?.index ?? 0,
                    bounds: lastChar?.bounds
                }
            }
        }

        // Convert bounds to relative from absolute.
        if (result?.charIndex !== null) {
            // Get the position of the mouse relative to the editor container
            const rect = editorContainer.getBoundingClientRect();

            result.bounds = {
                ...result.bounds,
                top: result.bounds.top - rect.top,
                bottom: result.bounds.bottom - rect.bottom,
                left: result.bounds.left - rect.left,
                right: result.bounds.right - rect.right,
            }
        }

        // reset the transform.
        if (originalTransform) {
            wrapper.style.transform = originalTransform;
        }

        return result;
    }

    getLineHeight() {
        const editor = this.editorInstance.getEditor();
        const lineDom = editor.getLine(0)[0].domNode;
        return parseFloat(getComputedStyle(lineDom)['line-height'].slice(0, -2));
    }

    findTextCharIndexes(searchStr) {
        const editor = this.getEditor();
        const text = editor.getText();
        const indexes = [];
        let startIndex = 0;
        let index;
    
        while ((index = text.indexOf(searchStr, startIndex)) > -1) {
            indexes.push(index);
            startIndex = index + searchStr.length;
        }
    
        return indexes;
    }

    findLineByCharIndex(charIndex) {
        const editor = this.getEditor();
        const text = editor.getText();
        const lines = text.split('\n');
        let cumulativeCharCount = 0;

        for (let i = 0; i < lines.length; i++) {
            cumulativeCharCount += lines[i].length + 1; // +1 for the newline character

            if (charIndex < cumulativeCharCount) {
                return i;
            }
        }

        return -1; // Return -1 if the character index is out of bounds
    }

    findHyperlinkIndexes() {
        const editor = this.getEditor();
        const content = editor.getContents();
        
        let lineIndex = 0;
        let charIndex = 0;
        let charCount = 0;

        const hyperlinks = [];
        for (const item of content.ops) {
            if (item?.attributes?.link) {
                hyperlinks.push({
                    lineIndex,
                    lineCharStart: charIndex,
                    lineCharEnd: charIndex + item.insert.length - 1,
                    start: charCount,
                    end: charCount + item.insert.length - 1
                });
            }

            if (item.insert === '\n' || item.insert.indexOf('\n') > -1) {
                lineIndex += 1;
                charIndex = 0;
            } else {
                charIndex += item.insert.length;
            }

            charCount += item.insert.length;
        }

        return hyperlinks;
    }

    getLineText(lineIndex) {
        const quill = this.getEditor();
        const text = quill.getText();
        const lines = text.split('\n');
        const lineText = lines[lineIndex];
        return lineText;
    }

    getEditor() {
        return this.editorInstance.getEditor();
    }

    getEditorDom() {
        return this.editorInstance.getEditor().root;
    }

    getWrapperInstance() {
        return this.wrapperInstance?.current;
    }

    getFabricObject() {
        return this.fabricObject; 
    }

    getSelection() {
        return this.editorInstance.editor.getSelection();
    }

    getSelectionStyles() {
        if (!this.editorInstance) return;
        const editor = this.getEditor();
        if (!editor) return;

        let selection = editor.getSelection();

        if (selection === null) {
            selection = { index: 0, length: editor.getLength() }
        }

        return editor.getFormat(selection);
    }

    /**
     * @deprecated
     * @param {number} padding 
     */
    handleEditorPadding(padding) {
        const editor = this.getEditor();
        if (!editor) return;
        editor.root.style.padding = typeof padding === 'string' ? padding : `${padding}px`;
    }

    setEditor(editorRef, fabricObject, wrapperInstance, arrowRefs) {
        this.editorInstance = editorRef.current;
        this.fabricObject = fabricObject;
        this.wrapperInstance = wrapperInstance;
        this.arrowRefs = arrowRefs;
        this._turnOffSpellCheck();
    }

    removeEditor() {
        this.editorInstance = null;
        this.fabricObject = null;
        this.wrapperInstance = null;
        this.arrowRefs = {};
    }

    isFormatApplied(type) {
        const currentFormat = this.getSelectionStyles();
        return currentFormat[type];
    }

    format(type, value, applyToAll = false) {
        const editor = this.getEditor();
        const actualType = this.fabricFormats[type] || type;

        let selection = editor.getSelection();
        if (!selection || applyToAll) {
            selection = {
                index: 0,
                length: editor.getLength()
            }
        }

        switch (actualType) {
            case 'link':
                editor.formatText(selection.index, selection.length, {
                    link: value,
                    underline: true,
                    color: 'rgb(0, 0, 255)'
                });
                break;
            case 'color':
            case 'align':
                editor.formatText(selection.index, selection.length, type, value);
                break;
            case 'link-selection':
                editor.formatText(selection.index, selection.length, 'background', 'rgba(17, 119, 255, 0.3)'); // Fabricjs default selection color.
                break;
            case 'link-selection-remove':
                editor.formatText(selection.index, selection.length, 'background', false);
                break;
            default:
                editor.formatText(selection.index, selection.length, actualType, !this.isFormatApplied(actualType))
        }
    }

    updateHyperlink(startIndex, endIndex, link) {
        const editor = this.getEditor();
        editor.formatText(startIndex, endIndex - startIndex + 1, { link });
    }

    removeHyperlink(startIndex, endIndex) {
        const editor = this.getEditor();
        if (!editor) return;

        if (!startIndex) startIndex = 0;
        let endIdx = endIndex - startIndex + 1;

        if (!endIndex) {
            endIdx = editor.getLength();
        }

        const format = editor.getFormat(startIndex, endIdx);

        // Reset hyperlink styles
        format.link = '';
        format.underline = false;
        format.color = '';
        
        editor.formatText(startIndex, endIdx, format);
    }

    /**
     * @deprecated
     * @param {fabric.Object} target 
     * @param {number} zoom 
     * @returns {string}
     */
    calculatePaddingOfTarget(target, zoom = 1) {
        if (!target) return;
        const { type, shapeType, scaleX } = target;

        let initPaddingValue = 24;
        let padding = Array(4).fill(initPaddingValue);

        if (type === 'group' && ['triangle', 'ellipse'].includes(shapeType)) {
            padding = padding.map(() => 0);
        } else if (type === 'group' && shapeType === 'rhombus') {
            padding = padding.map((p) => p / 2);
        } else if (type === 'group' && shapeType === 'sticky') {
            padding[2] = 0;
        }

        // Left - Right or Top - Bottom. So divide to 2.
        padding = padding.map((p) => (p / 2) * zoom * scaleX);
        return padding.join('px ') + 'px';
    }

    calculateHtmlEditorDimensions(target, canvas, options = {}) {
        const { scaleX, scaleY, angle, shapeType } = target;
        const [rect, textbox] = target.getObjects();
        const { strokeWidth } = rect;
        const { isTextOverflow, overflowStatus: { canScrollDown, canScrollUp } } = options;

        // Step-1: Define variables
        let transform = `rotate(${angle}deg)`;
        const padding = SHAPE_DEFAULTS.SHAPE_PADDING * scaleX;
        const textareaWidthRate = SHAPE_DEFAULTS.TEXTAREA_WIDTH_RATE[shapeType];
        const nonTextareaWidthRate = 1 - textareaWidthRate;
        canvas = canvas ?? target.canvas;
        const zoom = canvas ? canvas.getZoom() : 1;
        const { arrowSize, arrowMargin } = this._getArrowSizeForCalculateDimension(zoom);
        const isStickyNoteWidthIsUpdated = textbox.fixedWidth !== textbox.width;
        const scalingMode = this.getScalingMode(canvas);

        let centerPointYDiff = 0;

        const centerPoints = target.getCenterPoint();
        const dim = target._getTransformedDimensions();

        let w = dim.x / 2;
        let h = dim.y / 2;

        if (shapeType === 'ellipse') {
            w = rect.rx * scaleX;
            h = rect.ry * scaleY;
        }

        // Step-2: Decrease the stroke widths for better/certain calculation.
        if (!['ellipse'].includes(shapeType)) {
            if (target.width !== rect.width) {
                w -= strokeWidth * scaleX;
                h -= strokeWidth * scaleY;
            } else {
                w -= strokeWidth * scaleX / 2;
                h -= strokeWidth * scaleY / 2;
            }
        }

        // Step-3: Handle padding.
        if (['rect', 'parallelogram', 'sticky'].includes(shapeType)) {
            // Sticky note has fixed width, so no need to add padding.
            if (shapeType === 'sticky' && !isStickyNoteWidthIsUpdated) {
                w = SHAPE_DEFAULTS.STICKY_NOTE_WIDTH / 2 * scaleX;
            } else {
                w -= padding / 2;
            }

            let diff = 0;

            const arrowHeight = (arrowSize + arrowMargin) / 2;
            const oneCornerPadding = padding / 4;
            const bottomGap =  shapeType === 'sticky' ? SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT / 2 * scaleY : 0;

            if (isTextOverflow) {
                if (canScrollDown && !canScrollUp) {                    
                    if (shapeType === 'sticky') {
                        diff = oneCornerPadding + (arrowSize / 2) + bottomGap;
                        centerPointYDiff = oneCornerPadding - (arrowSize / 2) - bottomGap;
                    } else {
                        diff = oneCornerPadding + arrowHeight;
                        centerPointYDiff = oneCornerPadding - arrowHeight;
                    }
                }

                if (!canScrollDown && canScrollUp) {
                    if (shapeType === 'sticky') {
                        diff = bottomGap + (arrowSize / 2);
                        centerPointYDiff -= bottomGap - (arrowSize / 2);
                    } else {
                        diff = oneCornerPadding + arrowHeight;
                        centerPointYDiff -= oneCornerPadding - arrowHeight;
                    }
                }

                if (canScrollDown && canScrollUp) {

                    if (shapeType === 'sticky') {
                        diff = arrowSize + bottomGap;
                        centerPointYDiff -= bottomGap
                    } else {
                        diff = (arrowHeight * 2);
                    }
                }
            } else if (shapeType === 'sticky') {
                diff = (oneCornerPadding + bottomGap);
                centerPointYDiff = (dim.y / 2 - h + bottomGap / 2) * -1;
            } else {
                diff = padding / 2;
            }

            h -= diff;
        } else if (shapeType === 'triangle' && isTextOverflow) {
            h -= (arrowSize + arrowMargin);
        }

        // Step-4: Dimensions are calculated by shapeType.
        if (shapeType === 'rhombus') {
            w -= padding / 2;
            const aspectRatio = h / w;
            w *= textareaWidthRate;
            h = (w * aspectRatio) + (padding / 4);
        } else if (shapeType === 'ellipse') {
            const aspectRatio = h / w;
            w *= textareaWidthRate;
            h = w * aspectRatio;
        } else if (shapeType === 'parallelogram') {
            w = (w * textareaWidthRate) - (w * nonTextareaWidthRate);
        } else if (shapeType === 'triangle') {
            const triangleCoords = {
                bl: { x: centerPoints.x - (w / 2), y: centerPoints.y + (h / 2)},
                br: { x: centerPoints.x + (w / 2), y: centerPoints.y + (h / 2)},
                tl: { x: centerPoints.x - (w / 2), y: centerPoints.y - (h / 2)},
                tr: { x: centerPoints.x + (w / 2), y: centerPoints.y - (h / 2)},
            }

            const s1 = { x: triangleCoords.bl.x, y: triangleCoords.bl.y };
            const s2 = { x: triangleCoords.tl.x + (w / 2), y: triangleCoords.tl.y };
            const d1 = { x: triangleCoords.bl.x + (w * nonTextareaWidthRate / 2), y: triangleCoords.bl.y };
            const d2 = { x: triangleCoords.tl.x + (w * nonTextareaWidthRate / 2), y: triangleCoords.tl.y };

            const intersectedPoint = calculateIntersectionOfTwoLines(s1, s2, d1, d2);
            const newHeight = Math.abs(intersectedPoint.y - d1.y);
            centerPointYDiff = h - newHeight;
            h = newHeight;
            w *= textareaWidthRate;
        }

        // Step-4: If center point of the object is changed due to above updates, update center points of the object.
        if (centerPointYDiff !== 0) {
            if (angle !== 0) { // If there is an angle; calculate the X as well. Because x axis is also changing when y is updated in rotated objects.
                const theta = fabric.util.degreesToRadians(angle);
                const deltaX = centerPointYDiff * Math.sin(theta); // Vertical change impacts X axis after rotation
                const deltaY = centerPointYDiff * Math.cos(theta); // Vertical change impacts Y axis after rotation

                centerPoints.x -= deltaX;
                centerPoints.y += deltaY;
            } else {
                centerPoints.y += centerPointYDiff;
            }
        }

        // Step-5: Calculating object coordinates on canvas.
        const translateMatrix = [1, 0,0, 1, centerPoints.x, centerPoints.y];
        const aCoords = {
            tl: fabric.util.transformPoint({ x: -w, y: -h }, translateMatrix),
            tr: fabric.util.transformPoint({ x: w, y: -h }, translateMatrix),
            bl: fabric.util.transformPoint({ x: -w, y: h }, translateMatrix),
            br: fabric.util.transformPoint({ x: w, y: h }, translateMatrix)
        }

        // Step-6: Calculate the dimensions of the editor.
        let editorWidth = textbox.width * scaleX; // aCoords.tr.x - aCoords.tl.x; - I am using textbox width. Because there are some wrong textbox dimension calculations in prod.
        let editorHeight = aCoords.bl.y - aCoords.tl.y;
        const editorCenterPoints = canvas ? fabric.util.transformPoint(centerPoints, canvas.viewportTransform, false) : { x: 0, y: 0 };

        if (scalingMode === 'zoom') {
            editorCenterPoints.x /= zoom;
            editorCenterPoints.y /= zoom;
        } else {
            transform += ` scale(${zoom}, ${zoom})`;
        }

        const left = (editorCenterPoints.x) - (editorWidth / 2);
        const top = (editorCenterPoints.y) - (editorHeight / 2);

        if (options?.getScaledDims) {
            editorWidth /= scaleX;
            editorHeight /= scaleY;
        }

        return {
            width: editorWidth,
            height: editorHeight,
            left,
            top,
            transform,
            zoom: scalingMode === 'zoom' ? zoom : 1
        }
    }
    startEditingForLockManager(group, canvas, abortTextEditing) {
        const methods = [
            EDITING_METHODS.TEXT,
            EDITING_METHODS.TEXT_FONT_SIZE,
            EDITING_METHODS.TEXT_FONT_STYLE,
            EDITING_METHODS.DIMENSION,
        ];

        // Top position is updated for sticky notes..
        if (group.shapeType === 'sticky') {
            methods.push(EDITING_METHODS.TEXT_MOVE);
        }

        const { processId, aborted } = canvas.collaborationManager.startContinuousEditing(
            [group],
            canvas.pageId,
            ...methods
        )
        
        const abortionHandler = () => {
            abortTextEditing()
            group.htmlTextEditingAborted = true;
            canvas.discardActiveObject(); // Need to add it otherwise we can't open the text editor again.
            group.onShapeChanged()
            canvas.renderAll();
            group.editingProcessId = null;
            
        }
        
        if (aborted) {
            abortionHandler()
            return
        }
        
        group.editingProcessId = processId;
        
        canvas.collaborationManager.setAbortionListener(processId, abortionHandler)
    }

    stopEditingForLockManager(group, canvas) {
        canvas.collaborationManager.completeContinuousEditing(group.editingProcessId)
        group.editingProcessId = null;
    }
}

const instance = new HtmlEditorHelper();
export default instance;