import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { EMITTER_TYPES, TOOLBAR_MENU } from '../../../helpers/Constant';
import HtmlEditorHelper from '../../../helpers/HtmlEditorHelper';
import HyperlinkIcon from '../../svgIcons/Hyperlink';
import eventEmitter from '../../../helpers/EventEmitter';

const HyperlinkItem = ({
    isDisabled,
    onClick,
    showMenu,
    toggleMenu
}) => {
    const [hrefLink, setHrefLink] = useState('');
    const hyperlinkInputRef = useRef();
    const editorSelectionRangeRef = useRef();

    const resetHtmlEditorSelectionChanges = () => {
        const editor = HtmlEditorHelper.getEditor();
        const scrollTop = editor.root.scrollTop;
        editor.setSelection(editorSelectionRangeRef.current.index, editorSelectionRangeRef.current.length, 'silent')
        HtmlEditorHelper.format('link-selection-remove');
        editorSelectionRangeRef.current = null;

        // Quill is focusing to the editor so scroll is resetting in that case.
        setTimeout(() => {
            editor.root.scrollTop = scrollTop;
        }, 0);
    }

    const menuOnClickHandler = () => {
        if (isDisabled) return;
        toggleMenu(TOOLBAR_MENU.HYPERLINK);

        if (!showMenu) {
            if (HtmlEditorHelper.isHtmlEditorEnabled() && HtmlEditorHelper.isEditMode()) {
                let selection = HtmlEditorHelper.getSelection();
                if (selection) {
                    editorSelectionRangeRef.current = selection;
                    HtmlEditorHelper.format('link-selection');
                }
            }    
        } else if (editorSelectionRangeRef.current) {
            resetHtmlEditorSelectionChanges();
        }

        setTimeout(() => {
            hyperlinkInputRef.current?.focus();
        }, 0);
    }

    const addHyperlink = () => {
        if (editorSelectionRangeRef.current) {
            resetHtmlEditorSelectionChanges();
        }

        onClick(hrefLink);
        setHrefLink('');
    }

    const onValueChange = (e) => {
        let value = e.target.value || '';
        value = value.replaceAll(/\s+/g, '');
        setHrefLink(value);
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addHyperlink();
        }
    }

    const closeHyperLink = useCallback(() => {
        setHrefLink('');
    }, [setHrefLink]);

    useEffect(() => {
        eventEmitter.on(EMITTER_TYPES.RESET_HYPERLINK_VALUE, closeHyperLink)

        return () => {
            eventEmitter.off(EMITTER_TYPES.RESET_HYPERLINK_VALUE, closeHyperLink);
        }
    }, [closeHyperLink]);

    return (
        <div className="hyperLinkWrapper">
            <div className={clsx('addHyperlinkBtn button', { disabled: isDisabled })} onClick={menuOnClickHandler}>
                <HyperlinkIcon />
                <span className="tooltipText">Hyperlink</span>
            </div>

            {showMenu ? <div className="hyperlinkInputWrapper">
                <input
                    onChange={onValueChange}
                    onKeyDown={onKeyDown}
                    placeholder="Enter link here"
                    ref={hyperlinkInputRef}
                    type="url"
                    value={hrefLink}
                />
                <div className="actions">
                    <button type="button" onClick={addHyperlink}>Insert</button>
                </div>
            </div> : null}
        </div>
    )
};

HyperlinkItem.propTypes = {
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
}

export default HyperlinkItem;