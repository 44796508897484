import {fabric} from 'fabric';

/**
 * @param rgba
 */
export function getBrightnessFromRGBA(rgba) {
    var r = extractRGBAValues(rgba)[0];
    var g = extractRGBAValues(rgba)[1];
    var b = extractRGBAValues(rgba)[2];
    return (r * 299 + g * 587 + b * 114) / 1000;
}

export const chooseTextColorBasedOnBackground = (rgba) => {
    var brightness = getBrightnessFromRGBA(rgba);
    var textColor =
    brightness > 128 ? `rgba(60,62,73,1)` : `rgba(255, 255, 255, 1)`;
    return textColor;
}

export const getTextColorBaseOnShape = (shape) => {
    const rect = shape.getObjects().find(function (obj) {
        return obj.type === 'rect' || obj.type === 'triangle' || obj.type === 'ellipse' || obj.type === 'rhombus' || obj.type === 'parallelogram'
    });
    const backgroundColor = rect.fill;
    if (backgroundColor === 'transparent') return 'rgba(60, 62, 73, 1)'
    return chooseTextColorBasedOnBackground(backgroundColor);
} 

/**
 * @param rgbaString
 */
export function extractRGBAValues(rgbaString) {
    var rgbaValuesString = rgbaString.slice(5, -1);
    var rgbaValuesArray = rgbaValuesString.split(', ').map(Number);
    return rgbaValuesArray;
}

/**
 * Check if the given text style object has the given property.
 * @param {fabric.Textbox.styles} stylesObject Styles object that the desired property will be checked in.
 * @param {string} property The key of the desired property that will be checked in.
 * @returns {boolean} Returns true if the style includes the property.
 */
export function isTextStyleHasProperty(stylesObject, property) {
    if (!stylesObject || !property) {
        return false
    }
    
    try {
        let isThereProperty = false
        for (const lineStyles of Object.values(stylesObject)) {
            isThereProperty = Object.values(lineStyles).some(object => object.hasOwnProperty(property))
            if (isThereProperty) {
                return true
            }
        }
    } catch (err) {
        console.error(err)
    }

    return false
}
/**
 * 
 * @param {{ r: number, g: number, b: number, a: number }} color 
 * @returns {string}
 */
export const getRgbaAsString = (color) => {
    if (!color) return '';
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
}

/**
 * 
 * @param {{ r: number, g: number, b: number }} color 
 * @returns {string}
 */
export const getRgbAsString = (color) => {
    if (!color) return '';
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
}

export const hsvToHsl = (h, s, v, l = v * (1 - (s / 2))) => {
    return [h, l === 0 || l === 1 ? 0 : (v - l) / Math.min(l, 1 - l), l];
}

export const hslToHsv = (h, s, l, v = l + s * Math.min(l, 1 - l)) => {
    return [h, v === 0 ? 0 : 2 * (1 - (l / v)), v];
}

export const rgbaToHex = (colorStr, forceRemoveAlpha = true) => {
    if (colorStr.indexOf('rgba') === -1) { return colorStr; }
    // Check if the input string contains '/'
    const hasSlash = colorStr.includes('/')

    if (hasSlash) {
        // Extract the RGBA values from the input string
        const rgbaValues = colorStr.match(/(\d+)\s+(\d+)\s+(\d+)\s+\/\s+([\d.]+)/)

        if (!rgbaValues) {
            return colorStr // Return the original string if it doesn't match the expected format
        }

        const [red, green, blue, alpha] = rgbaValues.slice(1, 5).map(parseFloat)

        // Convert the RGB values to hexadecimal format
        const redHex = red.toString(16).padStart(2, '0')
        const greenHex = green.toString(16).padStart(2, '0')
        const blueHex = blue.toString(16).padStart(2, '0')

        // Convert alpha to a hexadecimal format (assuming it's already a decimal value in the range [0, 1])
        const alphaHex = forceRemoveAlpha
            ? ''
            : Math.round(alpha * 255)
                .toString(16)
                .padStart(2, '0')

        // Combine the hexadecimal values to form the final hex color string
        const hexColor = `#${redHex}${greenHex}${blueHex}${alphaHex}`

        return hexColor
    } else {
        // Use the second code block for the case when '/' is not present
        return (
            '#' +
            colorStr
                .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
                .split(',') // splits them at ","
                .filter((string, index) => !forceRemoveAlpha || index !== 3)
                .map(string => parseFloat(string)) // Converts them to numbers
                .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
                .map(number => number.toString(16)) // Converts numbers to hex
                .map(string => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
                .join('')
        )
    }
}