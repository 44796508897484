import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import { debounce } from '../../helpers/OptimizationUtils';
import { onWheelZoomInOut } from '../../hooks/PanZoom';

const MiniMap = ({
    canvas
}) => {
    const [minimapCanvas, setMinimapCanvas] = useState();
    const viewerRef = useRef();
    const isMouseMoveRef = useRef(false);

    /**
     * Find viewer exact location for minimap.
     */
    const findViewerLocation = useCallback(() => {
        const mainCanvasZoom = canvas.getZoom();

        const canvasLocation = {
            x:fabric.util.invertTransform(canvas.viewportTransform)[4] / canvas.getRetinaScaling(),
            y:fabric.util.invertTransform(canvas.viewportTransform)[5] / canvas.getRetinaScaling()
        }

        return { mainCanvasZoom, canvasLocation };
    }, [canvas]);

    /**
     * Adding viewer rectangle to minimap.
     * @param {fabric.Object} viewer
     */
    const addViewer = useCallback((viewer) => {
        const { mainCanvasZoom, canvasLocation } = findViewerLocation();
        if (minimapCanvas.getObjects().length === 0) { return; }

        const rect = new fabric.Rect({
            left: viewer ? viewer.left : canvasLocation.x,
            top: viewer ? viewer.top : canvasLocation.y,
            width: window.innerWidth,
            height: window.innerHeight,
            scaleX: viewer ? viewer.scaleX : mainCanvasZoom,
            scaleY: viewer ? viewer.scaleY : mainCanvasZoom,
            fill: 'transparent',
            backgroundColor: 'transparent',
            transparentCorners: false,
            stroke: '#333',
            strokeWidth: 1 / minimapCanvas.getZoom(),
            strokeUniform: true,
            originX: 'left',
            originY: 'top',
            id: 'minimapViewer',
            objectCaching: false,
            isDynamic: true
        });

        minimapCanvas.add(rect);
        viewerRef.current = rect;
    }, [minimapCanvas, canvas, findViewerLocation]);

    /**
     * Updating viewer scale when the board zoom changed.
     */
    const updateViewerScale = useCallback(() => {
        if (!viewerRef.current) { return; }
        const { mainCanvasZoom, canvasLocation } = findViewerLocation();

        viewerRef.current.set({
            left: canvasLocation.x * minimapCanvas.getRetinaScaling(),
            top: canvasLocation.y * minimapCanvas.getRetinaScaling(),
            scaleX: 1 / mainCanvasZoom,
            scaleY: 1 / mainCanvasZoom
        });
    }, [minimapCanvas, canvas, findViewerLocation]);

    /**
     * Change the viewer position/location in minimap when board pan is changed.
     */
    const updateViewerPosition = useCallback(() => {
        if (!viewerRef.current) { return; }
        const { canvasLocation } = findViewerLocation();

        viewerRef.current.set({
            left: canvasLocation.x * minimapCanvas.getRetinaScaling(),
            top: canvasLocation.y * minimapCanvas.getRetinaScaling(),
        });

        minimapCanvas.requestRenderAll();
    }, [minimapCanvas, canvas, findViewerLocation]);

    /**
     * When clicking to somewhere in the minimap, the viewer position changes in minimap. Canvas view location is also changes accordingly.
     */
    const changeViewerPosition = useCallback((pointer) => {
        if (!viewerRef.current) { return; }
        const mainCanvasZoom = canvas.getZoom();

        const left = pointer.x - (viewerRef.current.getScaledWidth() / 2);
        const top = pointer.y - (viewerRef.current.getScaledHeight() / 2);

        viewerRef.current.set({ left, top  });

        canvas.setViewportTransform([
            mainCanvasZoom, 0, 0, mainCanvasZoom,
            -left * mainCanvasZoom,
            -top * mainCanvasZoom
        ]);

        minimapCanvas.requestRenderAll();
    }, [minimapCanvas, canvas]);

    const isMousePointerOutOfMinimap = useCallback((pointer) => {
        const coords = minimapCanvas.vptCoords;

        if (
            pointer.x < coords.tl.x ||
            pointer.x > coords.tr.x ||
            pointer.y < coords.tl.y ||
            pointer.y > coords.bl.y
        ) {
            return false;
        }

        return true;
    }, [minimapCanvas]);

    const onMouseDown = useCallback((o) => {
        isMouseMoveRef.current = true;
        const pointer = minimapCanvas.getPointer(o.e);        
        changeViewerPosition(pointer, true);
        canvas.fire('mouse:down_from_minimap', o)
    }, [minimapCanvas, canvas, changeViewerPosition]);

    const onMouseMove = useCallback((o) => {
        if (isMouseMoveRef.current !== true)  { return; }
        const pointer = minimapCanvas.getPointer(o.e);
        if (!isMousePointerOutOfMinimap(pointer)) { return; }
        changeViewerPosition(pointer, true)
        canvas.fire('mouse:move_from_minimap', o)
    }, [minimapCanvas, canvas]);

    const onMouseUp = useCallback((o) => {
        isMouseMoveRef.current = false;
        const pointer = minimapCanvas.getPointer(o.e);
        if (!isMousePointerOutOfMinimap(pointer)) { return; }
        changeViewerPosition(pointer, true);
        canvas.fire('mouse:up_from_minimap', o)
    }, [minimapCanvas, canvas]);

    /**
     * Creating rectangle instead of all objects in whiteboard in order to present regarding object.
     * @param {fabric.Object} obj 
     * @returns {object}
     */
    const createRect = (obj) => {
        const rect = new fabric.Rect({
            top: obj.top,
            left: obj.left,
            width: Math.max(obj.width, 20),
            height: Math.max(obj.height, 20),
            strokeWidth: 0,
            skewX: obj.skewX,
            skewY: obj.skewY,
            flipX: obj.flipX,
            flipY: obj.flipY,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            zoomX: obj.zoomX,
            zoomY: obj.zoomY,
            fill: 'rgba(157, 180, 255, 0.8)',
            transparentCorners: true,
            centeredRotation: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockSkewingX: true,
            lockSkewingY: true,
            lockRotation: true,
            lockUniScaling: true,
            lockScalingFlip: true,
            hasControls: false,
            hasBorders: false,
            originX: obj.originX,
            originY: obj.originY,
            selectable: false,
            evented: false,
            objectCaching: false
        });

        rect.rotate(obj.angle);

        return rect.toJSON();
    }

    /**
     * After initializing or renewing the minimap, fits to all whiteboard objects into minimap canvas.
     */
    const fitCanvas = useCallback(() => {
        //first check if there are any elemnts to zoom to
        if (minimapCanvas.getObjects().length < 1) { return; }

        // reset zoom so pan actions work as expected
        minimapCanvas.setZoom(1);

        //group all the objects
        const objects = minimapCanvas.getObjects().filter((item) => item.id !== 'minimapViewer');
        const group = new fabric.Group(objects);

        const x = (group.left + (group.width / 2)) - (minimapCanvas.width / 2);
        const y = (group.top + (group.height / 2)) - (minimapCanvas.height / 2);

        //and pan to it
        minimapCanvas.absolutePan({x:x, y:y});

        // Find the ratio
        const ratioByHeight = minimapCanvas.getHeight() / group.height;
        const ratioByWidth = minimapCanvas.getWidth() / group.width;
        //work out hmow to scale the group to match the canvas size (then only make it zoom 80% of the way)
        const zoom = Math.min(ratioByHeight, ratioByWidth) * 0.8;
        //we've already panned the canvas to the centre of the group, so now zomm using teh centre of teh canvas as teh reference point
        minimapCanvas.zoomToPoint({ x: minimapCanvas.width / 2, y: minimapCanvas.height / 2 }, zoom);

        return [group, zoom];
    }, [minimapCanvas]);

    /**
     * After initializing or renewing the minimap, make all objects unselectable. Because minimap objects cannot be selectable.
     */
    const makeObjectsUnselectable = useCallback(() => {
        const objects = minimapCanvas.getObjects();
        objects.forEach((obj) => {
            obj.selectable = false;
            obj.evented = false;
        })
    }, [minimapCanvas])

    /**
     * Cloning all allowed board objects from board to minimap.
     */
    const cloneCanvasObjects = useCallback(() => {
        return new Promise((resolve) => {
            const canvasJSON = canvas.toJSON();
            const objects = [];

            const allowedObjectTypes = ['frame', 'group', 'path', 'curvedLine', 'image', 'optimizedImage', 'textbox', 'table'];

            for (const obj of canvasJSON.objects) {
                if (allowedObjectTypes.includes(obj.type)) {
                    const item = createRect(obj);
                    item.id = obj.uuid;
                    objects.push(item);
                }
            }

            canvasJSON.objects = objects;
            minimapCanvas.loadFromJSON(canvasJSON, () => {
                fitCanvas();
                minimapCanvas.requestRenderAll();
                resolve();
            });
        });
    }, [canvas, minimapCanvas, fitCanvas]);

    /**
     * Whenever there are some changes in board, this fn updated minimap accordingly.
     */
    const updateCanvas = useCallback(() => {
        const viewer = minimapCanvas.getObjects().find((item) => item.id === 'minimapViewer');

        cloneCanvasObjects(true)
            .then(() => {})
            .finally(() => {
                addViewer(viewer);
                minimapCanvas.requestRenderAll();
                makeObjectsUnselectable();
            });
    }, [canvas, minimapCanvas, cloneCanvasObjects, addViewer]);

    /**
     * Its listening the board zoom property. Its updating viewer scale according to the zoom.
     */
    const listenCanvasZoom = useCallback(() => {
        updateViewerScale();
        minimapCanvas.requestRenderAll();
    }, [canvas, minimapCanvas, updateViewerScale]);

    const _debounceUpdateCanvas = debounce(updateCanvas, 300);

    useEffect(() => {
        if (!canvas || !minimapCanvas) { return; }

        cloneCanvasObjects()
            .then(() => {})
            .finally(() => {
                minimapCanvas.requestRenderAll();
                addViewer();
                makeObjectsUnselectable();
                listenCanvasZoom();
            });

        
        // Canvas listeners
        canvas.on('object:modified', _debounceUpdateCanvas);
        canvas.on('object:removed', _debounceUpdateCanvas);
        canvas.on('object:added', _debounceUpdateCanvas);
        canvas.on('object:moved', _debounceUpdateCanvas);
        canvas.on('object:scaled', _debounceUpdateCanvas);
        canvas.on('object:rotated', _debounceUpdateCanvas);
        canvas.on('object:skewed', _debounceUpdateCanvas);
        canvas.on('modified-with-event', _debounceUpdateCanvas); // Added due to socket listener event.
        canvas.on('undo-redo', _debounceUpdateCanvas);
        canvas.on('line-added', _debounceUpdateCanvas);
        canvas.on('emit-drawn-connector', _debounceUpdateCanvas);

        canvas.on('board:zoom', listenCanvasZoom);
        canvas.on('board:pan', updateViewerPosition);

        // Minimap listeners
        minimapCanvas.on('mouse:down', onMouseDown)
        minimapCanvas.on('mouse:move', onMouseMove)
        minimapCanvas.on('mouse:up', onMouseUp)
        minimapCanvas.on('mouse:wheel', (opt) => {
            onWheelZoomInOut(opt, canvas);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            canvas.fire('mouse:wheel_from_minimap');
        });

        return () => {
            canvas.off('object:modified', _debounceUpdateCanvas);
            canvas.off('object:removed', _debounceUpdateCanvas);
            canvas.off('object:added', _debounceUpdateCanvas);
            canvas.off('object:moved', _debounceUpdateCanvas);
            canvas.off('object:scaled', _debounceUpdateCanvas);
            canvas.off('object:rotated', _debounceUpdateCanvas);
            canvas.off('object:skewed', _debounceUpdateCanvas);
            canvas.off('modified-with-event', _debounceUpdateCanvas);
            canvas.off('undo-redo', _debounceUpdateCanvas);
            canvas.off('line-added', _debounceUpdateCanvas);
            canvas.off('emit-drawn-connector', _debounceUpdateCanvas);

            canvas.off('board:zoom', listenCanvasZoom);
            canvas.off('board:pan', updateViewerPosition);

            minimapCanvas.off('mouse:down', onMouseDown)
            minimapCanvas.off('mouse:move', onMouseMove)
            minimapCanvas.off('mouse:up', onMouseUp)
        }
    }, [canvas, minimapCanvas, cloneCanvasObjects, makeObjectsUnselectable, addViewer]);

    useLayoutEffect(() => {
        const minimap = new fabric.Canvas('minimap', {
            containerClass: 'minimap',
            selection: false,
            width: 315,
            height: 205,
            enableRetinaScaling: true,
            interactive: false,
            skipOffscreen: false
        });

        setMinimapCanvas(minimap);
    }, []);

    return (
        <div className="miniMapWrapper">
            <canvas className="minimap" data-testid="minimap" id="minimap" />
        </div>
    )
};

MiniMap.propTypes = {
    canvas: PropTypes.instanceOf(fabric.Canvas)
}

export default MiniMap;