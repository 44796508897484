import { fabric } from 'fabric';
import { openShapeEditor, removeCanvasListener } from '../helpers/CommonFunctions'
import { SHAPE_DEFAULTS, modes } from '../helpers/Constant';
import store from '../redux/Store';
import { changeObjectsSelectableProp } from '../helpers/FabricMethods';
let mouseDown;

export const createSticky = (canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode, userId) => {
    mouseDown = true;
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.STICKY
    });
    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    onMouseDownLineHandler(canvas);
    canvas.on('mouse:up', (e) => {
        if (mouseDown) {
            drawSticky(e, canvas, emitOnMouseDown, userId);
            mouseDown = false;
            handleChangeSelectMode('select');
        }
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
};

export const drawSticky = (e, canvas, emitOnMouseDown, userId) => {
    const pointer = canvas.getPointer(e);
    const text = new fabric.Textbox('', {
        originX: 'center',
        originY: 'center',
        textAlign: 'center',
        fontSize: SHAPE_DEFAULTS.FONT_SIZE,
        fill: SHAPE_DEFAULTS.TEXT_COLOR,
        // splitByGrapheme: true,
        breakWords: true,
        fixedWidth: SHAPE_DEFAULTS.STICKY_NOTE_WIDTH,
        width: SHAPE_DEFAULTS.STICKY_NOTE_WIDTH,
        hasBorders: false,
        selectable: false,
        fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
        underline: false,
        fontStyle: 'normal',
        fontWeight: 'normal',
        lockScalingFlip : true,
        flipX :false,
        flipY:false
    })

    const rect = new fabric.Rect({
        width: 220,
        height: 220,
        fill: SHAPE_DEFAULTS.STICKY_NOTE_BACKGROUND,
        rx: 10,
        ry: 10,
        originX: 'center',
        originY: 'center',
        selectable: false,
        shadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        lockScalingFlip : true,
        flipX :false,
        flipY:false
    });

    const group = new fabric.Group([rect, text], {
        shapeType: 'sticky',
        left: pointer.x,
        top: pointer.y,
        originX: 'center',
        originY: 'center',
        lockScalingFlip : true,
        flipX :false,
        flipY:false,
        isLocked: false
    });
    editSticky(group, canvas, emitOnMouseDown, userId);
    canvas.add(group);
    emitOnMouseDown(group, group);
    changeObjectsSelectableProp(canvas, true);
    canvas.hoverCursor = 'all-scroll';
    canvas.setActiveObject(group);
    canvas.renderAll();
};

export const editSticky = (group, canvas) => {
    group.off('mousedblclick');

    group.on('mousedblclick', (e) => {
        if(!e.target){ return; }
        if(e.target.isLocked || (e.target.lockMovementX && e.target.lockMovementY)) return;
        if (!Array.isArray(e.target._objects) || e.target._objects.length !== 2) return;

        setTimeout(() => {
            openShapeEditor(e.target, canvas, { actionFrom: 'dblclick', event: e });
        }, 0);
    });
};

export const getOwnerOfSticky = (userId) => {
    const { board: { users }} = store.getState();
    if (!Array.isArray(users)) return {
        name: null,
        commentUsername: null
    }

    const owner = users.find((user) => user.id === userId);

    return {
        commentUsername: owner?.commentUsername,
        name: owner?.name
    }
}