import PropTypes from 'prop-types';


export const ArrowUpCircle = ({
    width = 12,
    height = 12
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={6} cy={6} r={6} fill="white" />
            <path
                d="M3.69999 7.08334L5.8837 5.04186C5.91232 5.01507 5.95116 5.00001 5.99166 5.00001C6.03216 5.00001 6.071 5.01507 6.09962 5.04186L8.28333 7.08334"
                stroke="#3C3E49"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

ArrowUpCircle.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}