import { fabric } from 'fabric';
import { getApproxMinLineWidth } from '../../TextWrapHelpers';
import { getLocalPoint, isTransformCentered, scaleObject, wrapWithFireEvent, wrapWithFixedAnchor } from '../CommonControlHelpers';
import { checkIsTextShortened, getShapeTextareaDimensions } from '../../shapes/Common';
import { EMITTER_TYPES, SHAPE_DEFAULTS, TEXTBOX_LINE_HEIGHT } from '../../Constant';
import eventEmitter from '../../EventEmitter';

/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeHeightOfSticky(e, transform, x, y) {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];
  
    const isHeightIncreasing = height > target.height;
    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY + SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT;

    if (textHeight + 30 < height || isHeightIncreasing) {
        image.set({
            height: height / image.scaleY,
        });
        target.set({
            height
        });
    }

    return oldHeight !== newHeight;
}


/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeWidthOfSticky(e, transform, x, y) {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;

    const width = Math.max(newWidth, 0);
    const textbox = targetObjects[1];
    const targetRect = targetObjects[0];

    const fontSizeForMinDims = Math.min(textbox.fontSize, SHAPE_DEFAULTS.FONT_SIZE);
    let defaultFont = `${fontSizeForMinDims}px / ${TEXTBOX_LINE_HEIGHT} ${textbox.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, target.shapeType);

    if (newWidth < minWidth) {
        return false;
    }

    targetRect.set({
        width: width / targetRect.scaleX,
    });
    target.set({
        width: width,
    });

    const { width: textWidth } = getShapeTextareaDimensions(target);

    textbox.set({
        width: textWidth
    });

    return (oldWidth !== newWidth);
}

/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeHeightOfFrame(e, transform, x, y) {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    target.set({
        height: height,
    });
    target.canvas.fire('frame-scaling', target);
    return oldHeight !== newHeight;
}

/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function changeWidthOfFrame(e, transform, x, y) {
    const target = transform.target,
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    if (newWidth < 100) {
        return false;
    }
    target.set({
        width: width,
    });
    target.canvas.fire('frame-scaling', target);
    return (oldWidth !== newWidth);
}

const changeHeightOfRect = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];

    const isHeightIncreasing = height > target.height;

    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;

    if (textHeight + 30 < height || textbox.text === '' || isHeightIncreasing) {
        image.set({
            height: height / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}

const changeWidthOfShape = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];

    const fontSizeForMinDims = Math.min(targetText.fontSize, SHAPE_DEFAULTS.FONT_SIZE);
    let defaultFont = `${fontSizeForMinDims}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, target.shapeType);

    if (newWidth < minWidth && targetText.text !== '' && newWidth <= target.width) {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX, });
    target.set({ width: width });

    const { width: textWidth } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    return (oldWidth !== newWidth);
}
const changeHeightOfRhombus = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];

    const isHeightIncreasing = height > target.height;

    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;
    
    if (textHeight + 20 < (height)/2  || textbox.text === '' || isHeightIncreasing) {
        image.set({
            height : height / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}

const changeWidthOfRhombus = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];

    const fontSizeForMinDims = Math.min(targetText.fontSize, SHAPE_DEFAULTS.FONT_SIZE);
    let defaultFont = `${fontSizeForMinDims}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, target.shapeType);

    if (newWidth < minWidth && targetText.text !== '' && newWidth <= target.width) {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX, });
    target.set({ width: width });

    const { width: textWidth } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    return (oldWidth !== newWidth);
}
const changeHeightOfParallelogram = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];

    const isHeightIncreasing = height > target.height;
    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;

    if (textHeight + 30 < height || textbox.text === '' || isHeightIncreasing) {
        image.set({
            height: height / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}

const changeWidthOfParallelogram = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];

    const fontSizeForMinDims = Math.min(targetText.fontSize, SHAPE_DEFAULTS.FONT_SIZE);
    let defaultFont = `${fontSizeForMinDims}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, target.shapeType);

    if (newWidth < minWidth && targetText.text !== '' && newWidth <= target.width) {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX, });
    target.set({ width: width, });

    const { width: textWidth } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    return (oldWidth !== newWidth);
}
const changeHeightOfEllipse = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const image = objects[0];
    const oldHeight = image.ry;
    const textHeight = textbox.__lineHeights.reduce((a, b) => a + b, 0);

    const isHeightIncreasing = height > target.height;

    if (textHeight * 1.5 < height || textbox.text === '' || isHeightIncreasing) {
        image.set({
            ry: (height / 2) / image.scaleY,
        });
        target.set({
            height,
        });
    }

    return oldHeight !== newHeight;
}
const changeWidthOfEllipse = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];
    const oldWidth = targetRect.rx * 2;

    const fontSizeForMinDims = Math.min(targetText.fontSize, SHAPE_DEFAULTS.FONT_SIZE);
    let defaultFont = `${fontSizeForMinDims}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, target.shapeType);

    if (width < minWidth  && targetText.text !== '' && newWidth <= target.width) {
        return false;
    }

    targetRect.set({ rx: (width / 2) / targetRect.scaleX, });
    target.set({ width: width });

    const { width: textWidth } = getShapeTextareaDimensions(target);
    targetText.set({ width: textWidth });

    return (oldWidth !== newWidth);
}

const changeHeightOfTriangle = (e, transform, x, y) => {
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;

    const topPoint = target.getPointByOrigin('center', 'top').y;
    const height = Math.max(newHeight, 0);
    const objects = target.getObjects();
    const textbox = objects[1];
    const rect = objects[0];

    // Getting textarea dimensions.
    const textHeight = (textbox.__lineHeights.reduce((a, b) => a + b, 0)) * textbox.scaleY;

    let textInstObj = getShapeTextareaDimensions(target);
    let isTriangleHasSpace = topPoint + (height * target.scaleY) - textInstObj.top - (textHeight * target.scaleY / 2) > (textInstObj.padding * target.scaleY / 2);
    const isHeightIncreasing = height > target.height;

    if (textbox.text === ''|| (textbox.text !== '' && isTriangleHasSpace) || isHeightIncreasing) {
        rect.set({ height: height / rect.scaleY });
        target.set({ height });
    }

    if (textbox.text !== '') {
        textInstObj = getShapeTextareaDimensions(target);
    
        // Get exact point the target inside the group object.
        const pointOnObject = target.toLocalPoint(
            new fabric.Point(textInstObj.left, textInstObj.top),
            textbox.originX,
            textbox.originY
        );

        textbox.set({ top: pointOnObject.y / target.scaleY });
    }


    return oldHeight !== newHeight;
}
const changeWidthOfTriangle = (e, transform, x, y) => {
    const target = transform.target,
        targetObjects = target.getObjects(),
        localPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    const width = Math.max(newWidth, 0);
    const targetRect = targetObjects[0];
    const targetText = targetObjects[1];

    const fontSizeForMinDims = Math.min(targetText.fontSize, SHAPE_DEFAULTS.FONT_SIZE);
    let defaultFont = `${fontSizeForMinDims}px / ${TEXTBOX_LINE_HEIGHT} ${targetText.fontFamily}`;
    const minWidth = getApproxMinLineWidth(defaultFont, target.shapeType);

    if (newWidth < minWidth && targetText.text !== '' && newWidth <= target.width) {
        return false;
    }

    targetRect.set({ width: width / targetRect.scaleX });

    // Getting textarea dimensions.
    const textInstObj = getShapeTextareaDimensions(target);

    // Get exact point the target inside the group object.
    const pointOnObject = target.toLocalPoint(
        new fabric.Point(textInstObj.left, textInstObj.top),
        targetText.originX,
        targetText.originY
    );

    targetText.set({
        width: textInstObj.width,
        top: pointOnObject.y / target.scaleY
    });

    target.set({
        width: width,
    });

    return (oldWidth !== newWidth); 
}

const changeWidthOfFrameHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfFrame));
const changeHeightOfFrameHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfFrame));
const changeWidthActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfSticky));
const changeHeightActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfSticky));
const changeWidthOfShapeActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfShape));
const changeHeightOfRectActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfRect));
const changeWidthOfEllipseActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfEllipse));
const changeHeightOfEllipseActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfEllipse));
const changeWidthOfTriangleActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfTriangle));
const changeHeightOfTriangleActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfTriangle));
const changeWidthOfRhombusActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfRhombus));
const changeHeightOfRhombusActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfRhombus));
const changeWidthOfParallelogramActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeWidthOfParallelogram));
const changeHeightOfParallelogramActionHandler = wrapWithFireEvent('resizing', wrapWithFixedAnchor(changeHeightOfParallelogram));

const scaleXHandler = (e, transform, x, y) => {
    return scaleObject(e, transform, x, y, { by: 'x'});
}

const scaleYHandler = (e, transform, x, y) => {
    return scaleObject(e, transform, x, y, { by: 'y'});
}
const scaleBothHandler = (e, transform, x, y) => {
    return scaleObject(e, transform, x, y);
}


const scaleXWithEvent = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleXHandler));
const scaleYWithEvent = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleYHandler));
const scaleEquallyWithEvent = wrapWithFireEvent('scaling', wrapWithFixedAnchor(scaleBothHandler));

// action handler for ML and MR contol
export const scaleXActionHandler = (e, transform, x, y) => {
    const { target } = transform;
    if (target.type === 'optimizedImage') {
        return;
    }

    arrangeTargetForCacheAndResize(target);

    // If html editor is enabled, update it as well.
    if (target.type === 'group' && target.isHtmlEditingMode === true) {
        eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_DIMENSIONS_UPDATED);
    }

    if (target.shapeType && target.shapeType === 'sticky') {
        return changeWidthActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'frame') {
        return changeWidthOfFrameHandler(e, transform, x, y);
    } else if (target.shapeType) {
        if (target.shapeType === 'rect') {
            return changeWidthOfShapeActionHandler(e, transform, x, y);
        } else if (target.shapeType === 'ellipse') {
            return changeWidthOfEllipseActionHandler(e, transform, x, y);
        } else if (target.shapeType === 'triangle') {
            return changeWidthOfTriangleActionHandler(e, transform, x, y);
        }else if (target.shapeType === 'rhombus') {
            return changeWidthOfRhombusActionHandler(e, transform, x, y);
        }
        else if (target.shapeType === 'parallelogram') {
            return changeWidthOfParallelogramActionHandler(e, transform, x, y);
        }
    }
    return scaleXWithEvent(e, transform, x, y);
}


// action handler for MT and MB contol
export const scaleYActionHandler = (e, transform, x, y) => {
    const { target } = transform;
    if (target.type === 'optimizedImage') {
        return;
    }

    arrangeTargetForCacheAndResize(target);

    // If html editor is enabled, update it as well.
    if (target.type === 'group' && target.isHtmlEditingMode === true) {
        eventEmitter.fire(EMITTER_TYPES.HTML_EDITOR_DIMENSIONS_UPDATED);

        const textboxObj = target.getObjects().find((obj) => obj?.type === 'textbox');
        if (textboxObj) {
            textboxObj.isTextShortened = checkIsTextShortened(textboxObj);
        }
    }

    if (target.shapeType && target.shapeType === 'sticky') {
        return changeHeightActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'frame') {
        return changeHeightOfFrameHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'rect') {
        return changeHeightOfRectActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'ellipse') {
        return changeHeightOfEllipseActionHandler(e, transform, x, y);
    } else if (target.shapeType && target.shapeType === 'triangle') {
        return changeHeightOfTriangleActionHandler(e, transform, x, y);
    }
    else if (target.shapeType && target.shapeType === 'rhombus') {
        return changeHeightOfRhombusActionHandler(e, transform, x, y);
    }
    else if (target.shapeType && target.shapeType === 'parallelogram') {
        return changeHeightOfParallelogramActionHandler(e, transform, x, y);
    }
    return scaleYWithEvent(e, transform, x, y);
}

export const scaleEquallyHandler = (e, transform, x, y) => {
    return scaleEquallyWithEvent(e, transform, x, y);
}

const arrangeTargetForCacheAndResize = (target) => {
    if (target.shapeType && (
        target.shapeType === 'rect' || 
        target.shapeType === 'ellipse' || 
        target.shapeType === 'triangle'
    )) {
        const actualShape = target.getObjects()[0];
        actualShape.set({
            objectCaching: false,
        })
        target.set({
            objectCaching: false,
        })
        // for backward compatibility
        if (actualShape.originX !== 'center' || actualShape.originY !== 'center') {
            const coordinates = actualShape.getCenterPoint();
            actualShape.set({
                originX: 'center',
                originY: 'center',
                left: coordinates.x,
                top: coordinates.y
            });
        }
    }
}
