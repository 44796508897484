import { fabric } from 'fabric';
import { SHAPE_DEFAULTS } from '../Constant';
import { wrapText } from '../TextWrapHelpers';
import { arrangeTextInsideShape, calculateIntersectionOfTwoLines, cloneFabricObjectHelper, createFabricInstance } from '../FabricMethods';

/**
 * @param {*} object 
 * @param {*} text 
 * @param {*} width 
 * @param {*} _fontSize 
 * @returns {{ lines: Array, newWrappedText: string }}
 */
export function wrapShapeText(object, text, width, _fontSize) {
    if (!object || object.type !== 'group') {
        return { lines: [], newWrappedText: '' }
    }

    const textbox = object.getObjects()[1];
    const fontSize = _fontSize ? _fontSize : textbox.fontSize;

    let newLines = [];
    const splittedTexts = text.split('\n');
    let deletedWhiteSpacesCount = [];

    textbox.isWrapping = true;
    for (let i = 0; i < splittedTexts.length; i++) {
        let { lines, removedWhiteSpacesByLineIndex } = wrapText(splittedTexts[i], fontSize, width, i, splittedTexts.length, textbox);
        if (!lines) lines = [];
        deletedWhiteSpacesCount = removedWhiteSpacesByLineIndex;
        newLines = newLines.concat(lines);
    }
    textbox.isWrapping = false;

    const newWrappedText = newLines.join('\n');
    return { lines: newLines, newWrappedText, deletedWhiteSpacesCount }
}

/**
 * If the shape doesn't have a minimum width or height to place text in it
 * then set the minimum width and height .
 * @param {{ group: fabric.Object, shape: fabric.Object, minWidth: number, minHeight: number, canvas: fabric.Canvas }} param0 
 */
export function setMinWidthAndHeightToShape({
    group,
    shape,
    minWidth,
    minHeight,
    canvas
}) {
    let isSet = false;

    if (group.shapeType === 'ellipse') {
        if (group.width < minWidth) {
            group.set({ width: (minWidth) });
            shape.set({ rx: (minWidth) / 2 })

            isSet = true;
        }

        if (group.height < minHeight) {
            group.set({ height: minHeight });
            shape.set({ ry: minHeight / 2 })

            isSet = true;
        }
    } else {
        if (group.width < minWidth) {
            const width = minWidth;
            group.set({ width });
            shape.set({ width });

            isSet = true;
        }

        if (group.height < minHeight) {
            group.set({ height: minHeight });
            shape.set({ height: minHeight });
            isSet = true;
        }
    }

    if (isSet) {
        // Dont turn this one to requestRenderAll. Some unexpected issues occuring for triangles.
        canvas.renderAll();
    }

    return isSet;
}

/**
 * Calculates the padding of the shapes.
 * @param {fabric.Object} object
 * @param {number=0} minWidth
 * @param {number=0} minHeight
 */
export function getShapeTextareaDimensions(object) {
    const objectType = object.type === 'group' ? object.shapeType : object.type;
    const shape = object.type === 'group' ? object.getObjects()[0] : object;
    
    const shapeInnerWidth = shape.width - shape.strokeWidth;
    const shapeInnerHeight = shape.height - shape.strokeWidth;
    let SHAPE_PADDING = SHAPE_DEFAULTS.SHAPE_PADDING;

    let top = ['rhombus', 'parallelogram', 'rect', 'ellipse', 'sticky'].includes(objectType) ? shapeInnerHeight / 2 : 0;
    let left = (shapeInnerWidth / 2) + shape.strokeWidth;
    let width = 1;
    let height = 1;
    let heightWithoutPadding = height;

    // Getting shape text area width rate.
    const textareaWidthRate = SHAPE_DEFAULTS.TEXTAREA_WIDTH_RATE[objectType];
    const nonTextareaWidthRate = 1 - textareaWidthRate;

    switch (objectType) {
        case 'triangle': {
            width = shapeInnerWidth * textareaWidthRate;
            // Don't use object.aCoords without calculating it. Sometimes it causing some issues.
            const coords = object._getCoords(true, true);

            const s1 = { x: coords.bl.x, y: coords.bl.y };
            const s2 = { x: coords.tl.x + (shapeInnerWidth / 2), y: coords.tl.y };
            const d1 = { x: coords.bl.x + (shapeInnerWidth * nonTextareaWidthRate / 2), y: coords.bl.y };
            const d2 = { x: coords.tl.x + (shapeInnerWidth * nonTextareaWidthRate / 2), y: coords.tl.y };

            const intersectedPoint = calculateIntersectionOfTwoLines(s1, s2, d1, d2);
            height = Math.abs(intersectedPoint.y - d1.y);
            top = d1.y - (height / 2);
            height /= object.scaleY;
            heightWithoutPadding = height;
            height -= SHAPE_PADDING;

            break;
        } case 'ellipse':
            width = (shape.rx * 2) * textareaWidthRate;
            height = (shape.ry * 2) * textareaWidthRate;
            heightWithoutPadding = height;
            left = shapeInnerWidth / 2 + shape.strokeWidth;
            break;
        case 'rhombus':
            width = (shapeInnerWidth * textareaWidthRate) - (SHAPE_PADDING / 2);
            height = (shapeInnerHeight * textareaWidthRate) - (SHAPE_PADDING / 2);
            heightWithoutPadding = (shapeInnerHeight * textareaWidthRate);
            break;
        case 'parallelogram':
            width = (shapeInnerWidth - SHAPE_PADDING) / 2;
            height = shapeInnerHeight - SHAPE_PADDING;
            heightWithoutPadding = shapeInnerHeight;
            break;
        case 'rect':
            width = shapeInnerWidth - SHAPE_PADDING;
            height = shapeInnerHeight - SHAPE_PADDING;
            heightWithoutPadding = shapeInnerHeight;
            break;
        default: // For ex. Sticky
            width = shapeInnerWidth - SHAPE_PADDING;
            height = shapeInnerHeight - SHAPE_PADDING;
            heightWithoutPadding = shapeInnerHeight;
            break;
    }

    return { top, left, width, height, padding: SHAPE_PADDING, heightWithoutPadding };
}


/**
 * Creates a fabric object from data.
 * @param data.data
 * @param {object} data - The object data that we want to create an fabric instance.
 * @param data.target
 * @param data.canvas
 * @returns 
 */
export function createInstanceForChangedShape({
    data,
    target,
    canvas
}) {
    return new Promise((resolve) => {
        createFabricInstance(data, function (objects) {
            objects.forEach(function (o) {
                o.toObject = cloneFabricObjectHelper(o);

                o.avoidEmittingOnAdd = true;
                canvas.add(o);

                const textObj = target._objects[1];
                textObj.visible = true;
                textObj.breakWords = true;
                textObj.splitByGrapheme = false;
                textObj.objectCaching = false;
                arrangeTextInsideShape(target);

                resolve(o);
            });
        });
    });
}

export const checkIsTextShortened = (textboxObject) => {
    const result = textboxObject._splitTextIntoLines(textboxObject.text, true);
    const isShortened = typeof result?.linesWithoutLimit !== 'undefined' && (result?.linesWithoutLimit !== result?.lines);
    return isShortened;
}