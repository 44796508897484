import isEqual from 'lodash.isequal';
import { Quill } from 'react-quill';
import { getTextColorBaseOnShape, rgbaToHex } from '../helpers/ColorHelper';

class DeltaHelper {
    text = '';
    styles = {};

    constructor() {}

    static isDelta(obj) {
        return obj && typeof obj === 'object' && Array.isArray(obj.ops);
    }

    _normalizeDelta(delta) {
        delta.ops = delta.ops.map((ops) => {
            if (ops?.attributes?.font) {
                delete ops.attributes.font;
            }

            // Align adjustment always will be emitted regardless of checking html edit mode.
            if (ops?.attributes?.align) {
                delete ops.attributes.align;
            }

            if (ops?.attributes?.color && ops.attributes.color.startsWith('rgba')) {
                ops.attributes.color = rgbaToHex(ops.attributes.color);
            }

            return ops;
        });

        return delta;
    }

    _convertStylesFromDeltaToFabric(attributes = {}, textboxObject) {
        const convertedStyle = {};

        if (attributes.bold === true) {
            convertedStyle.fontWeight = 'bold';
        }

        if (attributes.italic === true) {
            convertedStyle.fontStyle = 'italic';
        }

        if (attributes.color && rgbaToHex(textboxObject.fill) !== attributes.color) {
            convertedStyle.fill =  attributes.color;
        }

        if (attributes.link) {
            convertedStyle.isHyperlink = true;
            convertedStyle.url = attributes.link;
        }

        if (attributes.underline) {
            convertedStyle.underline = !!attributes.underline;
        }

        return convertedStyle;
    }

    // From fabric style to quill attributes
    _convertStylesFromFabricToDelta(style = {}) {
        let convertedStyle = {};

        if (style.fontWeight === 'bold') {
            convertedStyle.bold = true;
        }

        if (style.fontStyle === 'italic') {
            convertedStyle.italic = true;
        }

        if (style.fill) {
            convertedStyle.color = style.fill;
        }

        if (style.isHyperlink && style.url) {
            convertedStyle.link = style.url;
        }

        if (Number.isFinite(style.fontSize)) {
            convertedStyle.size = `${style.fontSize}px`;
        }

        if (style.fontFamily) {
            convertedStyle.font = style.fontFamily;
        }

        if (style.textAlign) {
            convertedStyle.align = style.textAlign;
        }

        if (style.underline) {
            convertedStyle.underline = style.underline;
        }

        return convertedStyle;
    }

    _getCommonStyles(shape, textboxObject) {
        return this._convertStylesFromFabricToDelta({
            fontFamily: textboxObject.fontFamily,
            textAlign: textboxObject.textAlign,
            fill: shape?.isTextColorApplied ? textboxObject.fill : getTextColorBaseOnShape(shape)
        })
    }

    _getLineBreakIndexes(text) {
        if (!text) {
            return {
                lines: [],
                lineBreakIndexes: []
            }
        }
        const lineBreakIndexes = [];

        const lines = text.split('\n');
        lines.forEach((item) => {
            lineBreakIndexes.push(item.length);
        });

        return { lines, lineBreakIndexes };
    }

    _splitTextByStyleChanges(lineIndex, lineText, styles) {
        const lineStyles = styles[lineIndex];

        // If its not first line, add line break.
        const ranges = [];

        // If there is no styles, return full text.
        if (!lineStyles) {
            return [{
                start: 0,
                end: lineText.length,
                text: lineText.slice(0, lineText.length),
                style: {}
            }];
        }

        // Initalizing styling start and end.
        const stylesArr = Object.entries(lineStyles);
        let styleStart = parseInt(stylesArr[0][0]);
        let styleEnd = parseInt(stylesArr[stylesArr.length - 1][0]);

        // Push non-styled text first if there are some non-styled chars before first styled char.
        if (styleStart > 0) {
            ranges.push({
                start: 0,
                end: styleStart,
                text: lineText.slice(0, styleStart),
                style: {}
            })
        }

        // Handle Styled chars
        if (stylesArr.length === 1) {
            ranges.push({
                start: styleStart,
                end: styleEnd + 1,
                text: lineText.slice(styleStart, styleEnd + 1),
                style: this._convertStylesFromFabricToDelta(stylesArr[0][1]),
            });
        } else {
            let lastIdx = 0;
            for (let i = 1; i < stylesArr.length; i++) {
                // In case of style is different from prev char
                if (
                    !isEqual(stylesArr[lastIdx][1], stylesArr[i][1]) ||
                    (stylesArr[i][0] - stylesArr[lastIdx][0] !== 1) // Sometimes styles should be equal even there are some other characters between them.
                ) {
                    styleEnd = parseInt(stylesArr[i - 1][0]);
                    ranges.push({
                        start: styleStart,
                        end: styleEnd + 1,
                        text: lineText.slice(styleStart, styleEnd + 1),
                        style: this._convertStylesFromFabricToDelta(stylesArr[lastIdx][1]),
                    });

                    styleStart = parseInt(stylesArr[i][0]);
                    lastIdx = i;

                    // It means there are some non-styled characters between styled chars.
                    if (styleStart - styleEnd > 1) {
                        const nonStyledTextLength = styleStart - styleEnd;
                        const start = styleEnd + 1;
                        const end = start + nonStyledTextLength - 1;

                        ranges.push({
                            start,
                            end,
                            text: lineText.slice(start, end),
                            style: {}
                        })
                    }
                }
    
                // If some styled texts are remained, push them
                if (i === stylesArr.length - 1) {
                    styleEnd = parseInt(stylesArr[i][0]);
                    ranges.push({
                        start: styleStart,
                        end: styleEnd + 1,
                        text: lineText.slice(styleStart, styleEnd + 1),
                        style: this._convertStylesFromFabricToDelta(stylesArr[lastIdx][1])
                    });
    
                    lastIdx = i;
                }
            }
        }

        // Push non-styled text if there are some non-styled chars after last styled char.
        if (styleEnd + 1 <= lineText.length - 1) {
            ranges.push({
                start: styleEnd + 1,
                end: lineText.length,
                text: lineText.slice(styleEnd + 1, lineText.length),
                style: {}
            })
        }

        return ranges;
    }

    convertTextToDelta(shape, textboxObject, text, styles) {
        const _delta = Quill.import('delta');
        const Delta = new _delta();

        const { lines } = this._getLineBreakIndexes(text);
        const { align, ...commonStyles} = this._getCommonStyles(shape, textboxObject);

        for (let i = 0; i < lines.length; i++) {
            const data = this._splitTextByStyleChanges(i, lines[i], styles);

            for (let j = 0; j < data.length; j++) {
                const item = data[j];
                let text = item.text;

                Delta.insert(text, {
                    ...commonStyles,
                    ...item.style
                });
            }

            // We should add line break to end of the line and we should also include align to adjust the alignment.
            Delta.insert('\n', { align })
        }

        return Delta;
    }

    convertDeltaToFabric(delta, options = {}) {
        let text = '', styles = {};
        const { textboxObject } = options;

        let lineIndex = 0;
        let charIndex = 0;

        for (const item of delta.ops) {
            const isLineBreakExist = item.insert.indexOf('\n') > -1;

            if (item.insert) {
                text += item.insert;
            }

            if (item.insert && item.insert !== '\n' && item.attributes) {
                const attributes = this._convertStylesFromDeltaToFabric(item.attributes, textboxObject);

                if (Object.keys(attributes).length > 0) {
                    if (!styles[lineIndex]) {
                        styles[lineIndex] = {};
                    }

                    Array.from(item.insert).forEach((t, idx) => {
                        styles[lineIndex][charIndex + idx] = attributes;
                    });
                }
            }

            if (isLineBreakExist) {
                lineIndex+= item.insert.split('\n').length - 1;
                charIndex = 0;
            } else {
                charIndex += item.insert.length;
            }
        }

        if (text.endsWith('\n')) {
            text = text.slice(0, text.length - 1);
        }

        return { text, styles };
    }

    compareDelta(delta1, delta2) {
        delta1 = this._normalizeDelta(delta1);
        delta2 = this._normalizeDelta(delta2);

        const diffDelta = delta1.diff(delta2);

        return {
            areEqual: diffDelta.ops.length === 0,
            diff: diffDelta
        }
    }
}

export default DeltaHelper;