/**
 * Renders controls and borders for the object
 * @param {CanvasRenderingContext2D} ctx Context to render on
 * @param {Object} [styleOverride] properties to override the object style
 * @param {Object} [childrenOverride] properties to override the children overrides
 */
export function drawControls(ctx) {
    let activeObject = this._activeObject;

    if (activeObject) {
        let styleOverride = {};

        if (activeObject && activeObject?.isHtmlEditingMode && activeObject?.htmlMode === 'edit') {
            styleOverride.hasBorders = false;
            styleOverride.hasControls = false;
        }

        activeObject._renderControls(ctx, styleOverride);
    }
}