import {useCallback, useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getActivityLogs } from '../../../services/ActivityLogService';
import ActivityLogItem from './activityLogItem/ActivityLogItem';
import transformLogList from '../../../helpers/activityLog/TransformLogList';
import useActivityLog from '../../../hooks/UseActivityLog';
import { onShapeDrawnEvent } from '../../../hooks/EventCatcher';
import { isUserHasAccessToFeature } from '../../../helpers/CommonFunctions';
import { getFabricObject, isObjectInsideOfObject } from '../../../helpers/FabricMethods';

import './ActivityLogTab.scss';
import createLogFromEvent, {createLogForThirdPartyImport} from '../../../helpers/activityLog/CreateLogFromEvent';
import { attachToFrame } from '../../../helpers/frame/FrameMethods';
import LoadingScreen from '../../loader/Loading';
import {setObjectsStackWithZIndex} from '../../../helpers/StackOrder';
import {EDITING_METHODS } from '../../../helpers/Constant';
import eventEmitter from '../../../helpers/EventEmitter';

const ActivityLogTab = ({ closeDrawer, whiteBoardId, canvas, users, currentUserId, userAccess }) => {
    const dispatch = useDispatch();
    const [activityLogs, setActivityLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const {
        setModifiedFrame
    } = useActivityLog(users, whiteBoardId, setActivityLogs, currentUserId, dispatch);
    const shouldActivityLogRefresh = useSelector(state => state.socket.shouldActivityLogRefresh);
    const activePageId = useSelector(state => state.rightDrawer?.activePage?.id);
    const activityHistory = useSelector(state => state?.history);
    
    const transformedLogs = useMemo(() => {
        return transformLogList(activityLogs);
    }, [activityLogs]);

    const isObjectValid = (obj) => {
        return obj.hasOwnProperty('uuid') && typeof obj.uuid === 'string' && obj.uuid.length > 0;
    }

    const listenAddObject = (obj)  => {
        setTimeout(() => {
            //check the username from object properties and remove it at the end.
            if (obj.target && isObjectValid(obj.target) && obj.target.avoidEmittingOnAdd !== true) {
                const addedItem = obj.target;

                // for initial load, hide data from activity log
                if (addedItem.hideFromActivityLog) {
                    addedItem.hideFromActivityLog = false;
                    return;
                }
                let eventName = 'created';
                if (addedItem.isDuplicated) {
                    eventName = 'duplicated';
                    addedItem.isDuplicated = false;
                } else if (addedItem.isRestored) {
                    eventName = 'modified';
                    addedItem.isRestored = false;
                }
                const logEvent = createLogFromEvent(eventName, addedItem, users, whiteBoardId, currentUserId);
                delete obj.target.user;
                setActivityLogs(prevState => [logEvent, ...prevState ]);
                dispatch({
                    type: 'activityHistory/modifyActivity',
                    payload:logEvent
                });
            }
        }, 0);
    }

    const listenModifyObject = (obj)  => {
        const modifiedObj = obj.target;
        if (modifiedObj && isObjectValid(modifiedObj)) {
            const logEvent = createLogFromEvent('modified', modifiedObj, users, whiteBoardId, currentUserId);
            setActivityLogs(prevState => [logEvent, ...prevState ]);
        } else if (modifiedObj.type === 'activeSelection') {
            for (const updatedObj of modifiedObj.getObjects()) {
                const logEvent = createLogFromEvent('modified', updatedObj, users, whiteBoardId, currentUserId);
                setActivityLogs(prevState => [logEvent, ...prevState ]);
                delete obj.target.user;
                dispatch({
                    type: 'activityHistory/modifyActivity',
                    payload:logEvent
                });
            }
        }
    }

    const listenDeleteObject = (obj)  => {
        // Don't remove setTimeout. Its added because this function fired before undo-redo-stack canvas event. It should be worked after it.
        setTimeout(() => {
            if (obj.target && isObjectValid(obj.target) && obj.target.avoidEmittingOnRemove !== true) {
                const targetObj = obj.target;
                // if deleted obj is deleted by current user
                if (!targetObj.deletedBy) {
                    targetObj.modifiedBy = currentUserId;
                }

                if (parseInt(targetObj.stackOrder) > -1) {
                    targetObj.stackOrder = parseInt(targetObj.stackOrder);
                }
    
                const logEvent = createLogFromEvent('deleted', obj.target, users, whiteBoardId, currentUserId);
                setActivityLogs(prevState => [logEvent, ...prevState]);
                delete obj.target.user;
                dispatch({
                    type: 'activityHistory/modifyActivity',
                    payload:logEvent
                });
            }
        }, 0);
    }

    const listenModifyFrame = (updatedFrame)  => {
        setModifiedFrame(updatedFrame);
    }
    
    const listenUpdateFrameLabel = (frameUuid) => {
        const frame = canvas.getObjects().find(obj => obj.uuid === frameUuid);
        if (frame) listenModifyObject({target: frame});
    }

    const listenThirdPartyBoardImported = (data) => {
        const logEvent = createLogForThirdPartyImport({
            time: data.nimaImportUpdatedAt,
            whiteBoardId,
            userId: data.nimaImportUpdatedBy,
            users
        })
        setActivityLogs(prevState => [logEvent, ...prevState]);
    }

    const handleRestoreItem = async (logItem) => {
        const processId = canvas.collaborationManager.startEditingScenario(canvas.pageId);
        if (logItem.shape.properties.attachedFrameId) {
            const frame = canvas.getObjects().find(item => item.uuid === logItem.shape.properties.attachedFrameId);
            canvas.collaborationManager.modifiedInScenario(frame, processId, EDITING_METHODS.FRAME_ATTACHMENTS);
            frame.attachments = [...frame.attachments, logItem.shape.properties.uuid]
        }
        await onShapeDrawnEvent({...logItem.shape, pageId: activePageId}, canvas, {
            modifiedBy: currentUserId,
            createdBy: currentUserId,
            isRestored: true
        }, activityHistory[activePageId]?.shapes);
        const mainRestoredObj = getFabricObject(canvas, 'uuid', logItem.shape.uuid);
        canvas.collaborationManager.restoredInScenario(mainRestoredObj, processId);

        const affectedObjects = [{...logItem.shape, pageId: activePageId}];
        const createdObjects = [mainRestoredObj];
        setObjectsStackWithZIndex(canvas);
        // if this is a frame, restore all items inside the frame
        try {
            let attachedShapes = []; 
            if (logItem.itemType && logItem.itemType === 'frame') {
                let logItemAttachments = logItem.shape.properties.attachments;
                attachedShapes = activityLogs.filter(log => {
                    if (logItemAttachments?.includes(log.shape.uuid)) {
                        // Same shape shouldn't be selected again.
                        logItemAttachments = logItemAttachments.filter((uuid) => uuid !== log.shape.uuid);
                        return true;
                    }
    
                    return false;
                });
            } else if (Array.isArray(logItem.shape.properties.lines) && logItem.shape.properties.lines.length > 0) {
                let lines = logItem.shape.properties.lines;

                attachedShapes = activityLogs.filter(log => {
                    if (lines.length === 0) return false;
                    if (lines?.includes(log.shape.uuid)) {
                        // Same shape shouldn't be selected again.
                        lines = lines.filter((uuid) => uuid !== log.shape.uuid);
                        return true;
                    }
    
                    return false;
                });
            }

            for (const attachedShape of attachedShapes) {
                // if the attachment of the frame is already in the canvas
                // instead of adding it again, try to attach it to the frame
                const attachedShapeInstance = canvas?.getObjects()?.find(obj => obj?.uuid === attachedShape?.shape?.uuid)
                if (attachedShapeInstance) {
                    if (isObjectInsideOfObject(mainRestoredObj, attachedShapeInstance, { manualCheck: true })) {
                        canvas.collaborationManager.modifiedInScenario(attachedShapeInstance, processId, EDITING_METHODS.ATTACHMENT_FRAME);
                        attachToFrame(attachedShapeInstance, mainRestoredObj, { allowAttachingToLockedFrame: true })
                    }

                    continue;
                }
                attachedShape.shape.pageId = activePageId;

                await onShapeDrawnEvent(attachedShape.shape, canvas, {
                    modifiedBy: currentUserId,
                    createdBy: currentUserId,
                    isRestored: true
                }, activityHistory[activePageId]?.shapes);
                const restoredObj = getFabricObject(canvas, 'uuid', attachedShape.shape.uuid);
                affectedObjects.push(attachedShape.shape);
                createdObjects.push(restoredObj);
                canvas.collaborationManager.restoredInScenario(restoredObj, processId);

                // handle restore for nested frames
                if (restoredObj.type === 'frame') {
                    let nestedLogItemAttachments = attachedShape?.shape?.properties?.attachments;
                    const nestedAttachedObjects = activityLogs.filter(log => {
                        if (nestedLogItemAttachments?.includes(log?.shape?.uuid)) {
                            nestedLogItemAttachments = nestedLogItemAttachments.filter((uuid) => uuid !== log.shape.uuid);
                            return true;
                        }
                        return false;
                    });
                    
                    for (const nestedAttachedShape of nestedAttachedObjects) {
                        nestedAttachedShape.shape.pageId = activePageId;
                        await onShapeDrawnEvent(nestedAttachedShape.shape, canvas, {
                            modifiedBy: currentUserId,
                            createdBy: currentUserId,
                            isRestored: true
                        }, activityHistory[activePageId]?.shapes);
                        const restoredObj = getFabricObject(canvas, 'uuid', nestedAttachedShape.shape.uuid);
                        affectedObjects.push(nestedAttachedShape.shape);
                        createdObjects.push(restoredObj);
                        canvas.collaborationManager.restoredInScenario(restoredObj, processId);
                    }
                } else if (restoredObj.type === 'curvedLine') {
                    restoredObj.calculateBoundingBoxForCurvedLine();
                }

            }
        } catch (err) {
            console.error('handleRestoreItem error: ', err);
        }

        if (affectedObjects.length) {
            canvas.collaborationManager.commitScenario(processId)
        }

        //find logItem from canvas
        const object = canvas.getObjects().find(i => i.uuid === logItem.shape.properties.uuid)
        if (object?.attachedFrameId) {
            const frame = canvas?.getObjects()?.find(e => e.uuid === object.attachedFrameId);
            if (isObjectInsideOfObject(frame, object, { manualCheck: true })) {
                attachToFrame(object, frame, { allowAttachingToLockedFrame: true })
            }
        }

        //reAttach the sub objects if they still inside of deleted frame
        const allObjectsExceptTarget = canvas.getObjects().filter(o => o !== object);
        if (allObjectsExceptTarget) {
            allObjectsExceptTarget.forEach(item => {
                if (isObjectInsideOfObject(object, item, { manualCheck: true }) && !item.attachedFrameId) {
                    attachToFrame(item, object, { allowAttachingToLockedFrame: true })
                }
            })
        }

        const currentItem = canvas.getObjects().find(i => i.uuid === logItem.shape.properties.uuid);

        // let newStackOrder = [];
        let childItems = [];

        if (!currentItem.attachmentFrameId && currentItem.attachments.length) {
            currentItem.attachments.forEach((a) => {
                childItems.push(canvas.getObjects().find(o => o.uuid === a));
            });
        }
        
        setObjectsStackWithZIndex(canvas);
    }
    const getActivityLogsAsync = useCallback(async () => {
        if (!isUserHasAccessToFeature('activity_log', userAccess)) { return; }

        setLoading(true);
        const response = await getActivityLogs(whiteBoardId, 1, activePageId);
        console.log(response);
        setActivityLogs(response.userFootsteps);
        dispatch({
            type: 'activityHistory/getActivity',
            payload: response.userFootsteps
        })
        setLoading(false);
    }, [whiteBoardId, activePageId]);

    useEffect(() => {
        if (whiteBoardId && activePageId) getActivityLogsAsync();
    }, [whiteBoardId, getActivityLogsAsync, activePageId]);

    useEffect(() => {
        if (shouldActivityLogRefresh) {
            if (whiteBoardId && activePageId) {
                getActivityLogsAsync();
                dispatch({
                    type: 'socket/setShouldActivityLogRefresh',
                    payload: false
                })
            }
        }
    }, [shouldActivityLogRefresh, getActivityLogsAsync, dispatch, whiteBoardId, activePageId]);

    useEffect(() => {
        const handleUpdateActivityLog = (data) => {
            setActivityLogs(prevState => prevState?.map(item => {
                return (item?.user?.id === data?.userId) ? {...item, user: {...item.user, name: data.name}} : item
            }))
        }
        
        if (canvas && users.length > 0) {
            canvas.on('object:added', listenAddObject);
            canvas.on('object:modified', listenModifyObject);
            canvas.on('modified-with-event', listenModifyObject)
            canvas.on('object:removed', listenDeleteObject);
            canvas.on('line-added', listenAddObject);
            // attached or detached object modifications
            canvas.on('frame-modified', listenModifyFrame);  
            // frame label update
            canvas.on('frame-text-updated', listenUpdateFrameLabel);
            canvas.on('third-party-board-imported', listenThirdPartyBoardImported);
            eventEmitter.on('updateTags', handleUpdateActivityLog);
        }

        return () => {
            if (canvas) {
                canvas.off('object:added', listenAddObject);
                canvas.off('object:modified', listenModifyObject);
                canvas.off('modified-with-event', listenModifyObject);
                canvas.off('object:removed', listenDeleteObject);
                canvas.off('line-added', listenAddObject);
                canvas.off('frame-modified', listenModifyFrame);
                canvas.off('frame-text-updated', listenUpdateFrameLabel);
                canvas.off('third-party-board-imported', listenThirdPartyBoardImported);
                eventEmitter.off('updateTags', handleUpdateActivityLog);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, users, whiteBoardId]);

    return (
        <div className="activityLogTab">
            <div className="activityLogTab__header">
                <div className="activityLogTab__header--left">
                    {/* <SwitchInput
                        label="Show changes"
                    /> */}
                </div>
                <div className="activityLogTab__header--right">
                    <button type="button" className="leftDrawer__closeBtn" onClick={closeDrawer}>
                        <em className="icon-close-circle" />
                    </button>
                </div>
            </div>
            <div className='activityLogTab__list'>
                {
                    !loading ? Object.keys(transformedLogs).map(objectKey => {
                        return <div
                            className='activityLogItem__wrapper'
                            data-date={objectKey}
                            key={objectKey}>
                            {
                                Object.keys(transformedLogs[objectKey]).map(userId => {
                                    const thisLogData = transformedLogs[objectKey][userId];
                                    return <ActivityLogItem
                                        canvas={canvas}
                                        handleRestoreItem={handleRestoreItem}
                                        key={userId}
                                        logData={thisLogData}
                                        userAccess={userAccess}
                                    />
                                })
                            }
                        </div>
                    })
                        : <div className="tab_loader">
                            <LoadingScreen/>
                        </div>
                }
            </div>
        </div>
    )
}

ActivityLogTab.propTypes = {
    closeDrawer: PropTypes.func.isRequired,
    whiteBoardId: PropTypes.number,
    canvas: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.string
    })),
    currentUserId: PropTypes.number,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']),
}

export default ActivityLogTab;